import React from 'react';

import Toolbar from '@mui/material/Toolbar';

import FederalLogo from '../assets/svg/images/FederalLogo';
import HeaderPopper from './HeaderPopper';

const toolbarStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
};
const Header = () => {
    return (
        <Toolbar sx={toolbarStyle}>
            <div style={{ paddingLeft: '15px' }}>
                <FederalLogo />
            </div>
            <HeaderPopper />
        </Toolbar>
    );
};

export default Header;
