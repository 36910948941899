import { createAsyncThunk } from '@reduxjs/toolkit';

import dayjs from 'dayjs';

import apiService from '../../../service/apiService';

export const getBCSeedsData = createAsyncThunk(
    'BCSEEDS_DATA',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiService.post('data/v1/dashboard/channels', {
                payload: {
                    products: payload,
                },
            });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getDisbursmentAnalysis = createAsyncThunk(
    'DISBURSMENT_ANALYSIS',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiService.post('data/v1/dashboard/analysis', {
                payload: payload,
            });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getStatesDrillDown = createAsyncThunk(
    'states_drilldown',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiService.post('data/v1/dashboard/states', {
                payload: { data: payload },
            });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getDashboardProductsList = createAsyncThunk(
    'DASHBOARD_PRODUCTS_LIST',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                'data/v1/dashboard/allProducts',
                {}
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getOverallData = createAsyncThunk(
    'DASHBOARD_SUMMARY_DATA',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiService.post('data/v1/dashboard/getSummary', {
                payload: {
                    data: {
                        products: payload,
                    },
                },
            });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getLoanDisbursementData = createAsyncThunk(
    'DASHBOARD_LOAN_DISBURSEMENT_DATA',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiService.post(
                'data/v1/dashboard/disbursement',
                {
                    payload: payload,
                }
            );
            const timestamp = dayjs().format('DD/MM/YY HH:mm:ss');
            return { ...response, timestamp };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
