import axios from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';

import { decrypt, encrypt } from './CryptographyService';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
const secureMode = process.env.REACT_APP_SECURE_MODE;
const instance = axios.create();
const apiService = setupCache(instance, {
    storage: buildWebStorage(sessionStorage, 'axios-cache:'),
    methods: ['get', 'post'],
    ttl: 30000,
});

const encryptRequestBody = (data) => {
    const requestPayload = { payload: encrypt(data?.payload) };
    return requestPayload;
};

const addHeadersInterceptor = (config) => {
    const accToken = window.sessionStorage.getItem('aT');
    const refToken = window.sessionStorage.getItem('rT');
    const partnerId = window.sessionStorage.getItem('partnerId');
    const authConfig = {
        ...config,
        headers: {
            ...config.headers,
        },
    };

    const token = config.url === '/auth/v2/auth/logout' ? refToken : accToken;
    if (token && partnerId) {
        authConfig.headers.Authorization = `Bearer ${token}`;
        authConfig.headers['partner-id'] = partnerId;
    }
    return authConfig;
};

const encryptRequestBodyInterceptor = (config) => {
    if (secureMode && config.method === 'post') {
        config.data = encryptRequestBody(config.data);
    }
    return config;
};

apiService.interceptors.request.use(addHeadersInterceptor);
apiService.interceptors.request.use(encryptRequestBodyInterceptor);

apiService.interceptors.response.use(
    (response) => {
        if (secureMode) {
            return JSON.parse(decrypt(response?.data?.payload));
        }
        return response?.data?.payload;
    },
    (error) => {
        if (error?.response?.status === 401) {
            window.sessionStorage.clear();
            window.location.href = '/login';
            window.localStorage.setItem('API_error_with_401', JSON.stringify(error));
        }
        return Promise.reject(error);
    }
);

export default apiService;
