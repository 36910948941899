import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../service/apiService';

export const getCustomerLifecycleMasterLevel = createAsyncThunk(
    'CUSTOMER_LIFECYCLE_MASTER_LEVEL',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                '/lifecycle/v1/customer/master/levels'
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getLatestReportDownloadLink = createAsyncThunk(
    'GET_LATEST_REPORT_DOWNLOAD_LIST',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiService.post(
                '/data/v1/report/getreport',
                {
                    payload,
                },
                { cache: false }
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
