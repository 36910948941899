import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { selectLoginData } from '../features/login/redux/selector';
import { routeConstant } from './routeConstant';

const isAuthenticated = (roleCode, roles) => {
    return roleCode && roles?.includes(roleCode);
};

const PrivateRoute = ({ element, roles }) => {
    const userData = useSelector(selectLoginData);

    if (isAuthenticated(userData?.data?.roleCode, roles)) {
        return element;
    } else {
        return <Navigate to={`/${routeConstant.LOGIN}`} />;
    }
};

export default PrivateRoute;
PrivateRoute.propTypes = {
    element: PropTypes.element.isRequired,
    roles: PropTypes.array.isRequired,
};
