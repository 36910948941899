import { createSlice } from '@reduxjs/toolkit';

import { getCustomerDashboardList } from './thunk';

const initialState = {
    customerDashboardList: {
        data: {},
    },
};

export const customerDashboardSlice = createSlice({
    name: 'customerDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCustomerDashboardList.fulfilled, (state, action) => {
                state.customerDashboardList.data = action.payload.data;
            })
            .addCase('prospectReport/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

export default customerDashboardSlice.reducer;
