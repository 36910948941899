export const isEmptyObject = (obj) => {
    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false;
        }
    }
    return true;
};

export const areObjectKeysEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    const keySet = new Set(keys1);

    for (const key of keys2) {
        if (!keySet.has(key)) {
            return false;
        }
    }

    return true;
};

export const findCollectionDay = (collectionDay) => {
    const weekdays = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    if (!collectionDay) return 'Invalid Day'; // if null/undefined/empty string

    if (typeof collectionDay === 'string') return collectionDay;

    if (collectionDay >= 1 && collectionDay <= 6) {
        return weekdays[collectionDay - 1];
    } else return 'Invalid Day';
};

export const findCollectionWeek = (collectionWeek) => {
    if (!collectionWeek) return 'Invalid Week'; // if null/undefined/empty string

    if (typeof collectionWeek === 'string') return collectionWeek;

    switch (collectionWeek) {
        case 1:
            return '1st Week';
        case 2:
            return '2nd Week';

        default:
            return collectionWeek;
    }
};

export const dateNullCheck = (dateValue) => {
    if (dateValue === null || isNaN(new Date(dateValue))) {
        return '-';
    }
    return new Date(dateValue).toLocaleDateString();
};

export function flattenArrayAndRemoveNull(arr) {
    return arr.reduce((flattened, item) => {
        if (Array.isArray(item)) {
            flattened.push(...flattenArrayAndRemoveNull(item));
        } else if (item !== null) {
            flattened.push(item);
        }
        return flattened;
    }, []);
}
