import * as CONS from './filterPayloadConfig';

function auditFilter(distinctFilters) {
    const output = {
        stage: distinctFilters.stage.map((label, index) => ({
            filterCategory: 'stage',
            label: label,
            filterID: `stage${index + 1}`,
            icon: '',
        })),
        pincode: distinctFilters.pincode.map((label, index) => ({
            filterCategory: 'pincode',
            label: label,
            filterID: `pincode${index + 1}`,
            icon: '',
        })),
        productCode: distinctFilters.product.map((label, index) => ({
            filterCategory: 'productCode',
            label: label,
            filterID: `product${index + 1}`,
            icon: '',
        })),
        partner: distinctFilters.partner.map((label, index) => ({
            filterCategory: 'partner',
            label: label,
            filterID: `partner${index + 1}`,
            icon: '',
        })),
        village: distinctFilters.village.map((label, index) => ({
            filterCategory: 'village',
            label: label,
            filterID: `village${index + 1}`,
            icon: '',
        })),
    };

    return output;
}
function loanApprovalFilter(distinctFilters) {
    const output = {
        // stage: distinctFilters?.stage?.map((label) => ({
        //     filterCategory: 'stage',
        //     label: label,
        //     filterID: label,
        //     icon: '',
        // })),
        pincode: distinctFilters?.pincode?.map((label) => ({
            filterCategory: 'pincode',
            label: label,
            filterID: label,
            icon: '',
        })),
        productCode: distinctFilters?.products?.map((label) => ({
            filterCategory: 'productCode',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
        stakeholderCode: distinctFilters?.stakeholders?.map((label) => ({
            filterCategory: 'stakeholderCode',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
        // village: distinctFilters?.villages?.map((label) => ({
        //     filterCategory: 'villageName',
        //     label: label,
        //     filterID: label,
        //     icon: '',
        // })),
    };

    return output;
}
function customerDashboardFilter(distinctFilters) {
    const output = {
        stateCode: distinctFilters?.states?.map((label) => ({
            filterCategory: 'stateCode',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
        districtCode: distinctFilters?.districts?.map((label) => ({
            filterCategory: 'districtCode',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
        productCode: distinctFilters?.products?.map((label) => ({
            filterCategory: 'productCode',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
        bc: distinctFilters?.bc?.map((label) => ({
            filterCategory: 'bc',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
        stage: distinctFilters?.stage?.map((label) => ({
            filterCategory: 'stage',
            label: label,
            filterID: label,
            icon: '',
        })),
        // partnerIds: distinctFilters.partnerIds.map((label) => ({
        //     filterCategory: 'partnerIds',
        //     label: label,
        //     filterID: label,
        //     icon: '',
        // })),
        status: distinctFilters?.status?.map((label) => ({
            filterCategory: 'status',
            label: label,
            filterID: label,
            icon: '',
        })),

        // village: distinctFilters?.villages?.map((label) => ({
        //     filterCategory: 'villageName',
        //     label: label,
        //     filterID: label,
        //     icon: '',
        // })),
    };

    return output;
}
function groupReportFilter(distinctFilters) {
    const output = {
        stage: distinctFilters?.stage?.map((label) => ({
            filterCategory: 'stage',
            label: label,
            filterID: label,
            icon: '',
        })),
        pincode: distinctFilters?.pincode?.map((label) => ({
            filterCategory: 'pincode',
            label: label,
            filterID: label,
            icon: '',
        })),
        productCode: distinctFilters?.products?.map((label) => ({
            filterCategory: 'productCode',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
        stakeholderCode: distinctFilters?.stakeholders?.map((label) => ({
            filterCategory: 'stakeholderCode',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
    };

    return output;
}

function disbursalReportFilter(distinctFilters) {
    const output = {
        stage: distinctFilters?.stage?.map((label) => ({
            filterCategory: 'stage',
            label: label,
            filterID: label,
            icon: '',
        })),
        pincode: distinctFilters?.pincode?.map((label) => ({
            filterCategory: 'pincode',
            label: label,
            filterID: label,
            icon: '',
        })),
        productCode: distinctFilters?.products?.map((label) => ({
            filterCategory: 'productCode',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
        stakeholderCode: distinctFilters?.stakeholders?.map((label) => ({
            filterCategory: 'stakeholderCode',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
    };

    return output;
}

function customerSupportFilter(distinctFilters) {
    const output = {
        productCode: distinctFilters?.products?.map((label) => ({
            filterCategory: 'productCode',
            label: label?.name,
            filterID: label?.code,
            icon: '',
        })),
        apiName: distinctFilters?.apiName?.map((label) => ({
            filterCategory: 'apiName',
            label: label,
            filterID: label,
            icon: '',
        })),
    };
    return output;
}

function dashIfValueIsNull(value) {
    return value !== null ? value : '-';
}

function filterValuesToPayload(filteredValues, filterPayloadName) {
    let tempFilterCategory = JSON?.parse(JSON?.stringify(CONS[filterPayloadName]));

    for (const item of filteredValues) {
        const filterCategory = item.filterCategory;
        const filterID = item.filterID;

        if (filterCategory in tempFilterCategory) {
            tempFilterCategory[filterCategory].push(filterID);
        }
        if (filterCategory === 'search' && filterID) {
            if (filterID) {
                tempFilterCategory.searchString = filterID;
            }
        }
        if (filterCategory === 'date_picker')
            if (item?.fromDate && item?.toDate) {
                tempFilterCategory.date.startDate = item?.fromDate;
                tempFilterCategory.date.endDate = item?.toDate;
            } else {
                if (filterID === '7days') {
                    tempFilterCategory.date.startDate = last7daysDateRange()[0];
                    tempFilterCategory.date.endDate = last7daysDateRange()[1];
                } else if (filterID === '15days') {
                    tempFilterCategory.date.startDate = last15daysDateRange()[0];
                    tempFilterCategory.date.endDate = last15daysDateRange()[1];
                } else if (filterID === '30days') {
                    tempFilterCategory.date.startDate = last30daysDateRange()[0];
                    tempFilterCategory.date.endDate = last30daysDateRange()[1];
                } else if (filterID === '3months') {
                    tempFilterCategory.date.startDate =
                        getDate3MonthsAgoTodayYYYYMMDD();
                    tempFilterCategory.date.endDate = getTodayYYYYMMDD();
                } else if (filterID === '6months') {
                    tempFilterCategory.date.startDate =
                        getDate6MonthsAgoTodayYYYYMMDD();
                    tempFilterCategory.date.endDate = getTodayYYYYMMDD();
                }
            }
    }

    return tempFilterCategory;
}

const formatDate = (date) => {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
};

const getTodayYYYYMMDD = () => {
    let d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const date = d.getDate();
    return (
        year +
        '-' +
        (month > 10 ? month : '0' + month) +
        '-' +
        (date >= 10 ? date : '0' + date)
    );
};

const last7daysDateRange = () => {
    var today = new Date();
    var sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // Calculate 7 days ago
    return [formatDate(sevenDaysAgo), formatDate(today)];
};

const last15daysDateRange = () => {
    var today = new Date();
    var sevenDaysAgo = new Date(today.getTime() - 15 * 24 * 60 * 60 * 1000); // Calculate 7 days ago
    return [formatDate(sevenDaysAgo), formatDate(today)];
};

const last30daysDateRange = () => {
    var today = new Date();
    var thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000); // Calculate 30 days ago
    return [formatDate(thirtyDaysAgo), formatDate(today)];
};

const getDate3MonthsAgoTodayYYYYMMDD = () => {
    var date = new Date();
    var fromDate = new Date(date.getFullYear(), date.getMonth() - 3, date.getDate());

    return (
        fromDate.getFullYear() +
        '-' +
        (fromDate.getMonth() + 1 > 9
            ? fromDate.getMonth() + 1
            : '0' + (fromDate.getMonth() + 1)) +
        '-' +
        (fromDate.getDate() > 9 ? fromDate.getDate() : '0' + fromDate.getDate())
    );
};

const getDate6MonthsAgoTodayYYYYMMDD = () => {
    var date = new Date();
    var fromDate = new Date(date.getFullYear(), date.getMonth() - 6, date.getDate());

    return (
        fromDate.getFullYear() +
        '-' +
        (fromDate.getMonth() + 1 > 9
            ? fromDate.getMonth() + 1
            : '0' + (fromDate.getMonth() + 1)) +
        '-' +
        (fromDate.getDate() > 9 ? fromDate.getDate() : '0' + fromDate.getDate())
    );
};

export {
    auditFilter,
    customerDashboardFilter,
    customerSupportFilter,
    dashIfValueIsNull,
    disbursalReportFilter,
    filterValuesToPayload,
    groupReportFilter,
    loanApprovalFilter,
};
