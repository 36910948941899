import React from 'react';

const FederalLogo = () => {
    return (
        <svg
            width="181"
            height="54"
            viewBox="0 0 181 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 53.7284H181V5.05652e-06H0V53.7284Z" className="fill-blue" />
            <path
                d="M0 53.7284H181V48.358H0V53.7284Z"
                className="fill-primary-light"
            />
            <path
                d="M20.9587 19.0528H26.2817L26.566 16.44L18.1814 16.4163L16.0162 32.4536H19.0804L20.1018 25.326H25.4485L25.8039 22.6856H20.4599L20.9587 19.0528Z"
                fill="white"
            />
            <path
                d="M37.0926 19.0322L37.4493 16.4155L28.2263 16.4392L26.115 32.5186C26.115 32.5186 35.2353 32.6792 35.2353 32.4949C35.2353 32.3172 35.5473 29.948 35.5473 29.948H29.4425L30.0598 25.3477H36.1883L36.5674 22.6625H30.4863L30.9838 19.0322H37.0926Z"
                fill="white"
            />
            <path
                d="M62.0726 19.0322L62.432 16.4155L53.2116 16.4392L51.0977 32.5186C51.0977 32.5186 60.2193 32.6792 60.2193 32.4949C60.2193 32.3172 60.53 29.948 60.53 29.948H54.4212L55.0412 25.3477H61.171L61.5514 22.6625H55.469L55.9665 19.0322H62.0726Z"
                fill="white"
            />
            <path
                d="M44.168 29.928C44.168 29.928 41.2051 30.1096 40.6575 29.9885L40.6523 30.0227L40.5391 30.0188L41.9672 19.0688L44.2298 19.0306C44.2298 19.0306 47.4678 19.0306 48.3998 22.6359L48.4142 22.703C49.3251 26.3767 46.6412 29.6265 44.168 29.928ZM43.4269 16.46L39.282 16.4429L37.1879 32.5183H40.2113L40.2126 32.5091L40.4127 32.5052C40.9339 32.5513 44.4615 32.8224 46.7689 31.6549C46.7689 31.6549 51.4416 29.9885 51.3969 23.323C51.3969 23.323 51.1244 17.2037 43.4269 16.46Z"
                fill="white"
            />
            <path
                d="M66.534 23.4915L67.1158 19.0162C67.1158 19.0162 70.13 18.5371 70.5236 20.1376C70.5236 20.1376 71.2146 23.1111 68.0767 23.5046L66.534 23.4915ZM73.5036 20.8892C73.5036 20.8892 73.576 16.6996 68.8019 16.46H64.3503L62.2325 32.5394H65.3546L66.2194 25.9068L66.6301 25.9318L66.6314 25.9292L70.4881 32.5394H73.6826L69.7667 25.6251C69.7667 25.6251 73.1811 25.0802 73.5036 20.8892Z"
                fill="white"
            />
            <path
                d="M81.1995 26.4967L83.6728 22.3926L84.8693 26.5191L81.1995 26.4967ZM74.0562 32.5028H77.5785L79.6398 29.0858H85.6143L86.6133 32.5304H89.8197L84.4941 15.781L74.0562 32.5028Z"
                fill="white"
            />
            <path
                d="M95.5937 16.4359L92.6282 16.4596L90.4576 32.4798H98.8027L99.1199 29.9367H93.951L95.5937 16.4359Z"
                fill="white"
            />
            <path
                d="M124.275 26.4967L126.749 22.3926L127.944 26.5191L124.275 26.4967ZM117.135 32.5028H120.654L122.716 29.0858H128.689L129.689 32.5304H132.894L127.569 15.781L117.135 32.5028Z"
                fill="white"
            />
            <path
                d="M114.127 29.343C114.127 29.343 113.327 30.3657 110.248 30.0156L110.159 30.0248L110.794 25.3784C111.309 25.3429 112.516 25.2863 112.925 25.514C112.925 25.514 115.941 26.159 114.127 29.343ZM111.666 19.0248L111.725 18.7629C111.725 18.7629 113.603 18.6471 114.281 19.6106C114.281 19.6106 115.604 21.8627 112.773 22.9144L111.122 22.9973L111.666 19.0248ZM115.483 24.1385C115.483 24.1385 117.973 22.2997 117.327 19.8751C117.327 19.8751 117.022 16.778 112.802 16.4555H108.74L106.646 32.527H109.386H109.815L109.822 32.481C110.213 32.4994 110.722 32.5244 111.358 32.5573C111.358 32.5573 115.449 32.5573 116.837 30.5408C116.837 30.5408 119.545 27.0053 115.483 24.1385Z"
                fill="white"
            />
            <path
                d="M148.051 16.4252L146.729 26.305L136.198 15.7249L134.109 32.498H137.062L138.509 21.6283L148.946 32.731L151.224 16.4252H148.051Z"
                fill="white"
            />
            <path
                d="M164.983 16.4556H161.476L155.562 22.0694L156.303 16.4556L153.131 16.4266L151.007 32.5284H154.181L155.023 26.1393L155.524 25.6536L161.012 32.4692H164.616L157.809 23.4304L164.983 16.4556Z"
                fill="white"
            />
            <path
                d="M18.6551 38.6097C18.3945 38.1753 18.0772 37.662 17.6995 37.071L17.1519 36.2352C16.8966 35.8508 16.7189 35.5915 16.6267 35.452C16.528 35.3151 16.4675 35.2295 16.4398 35.1993C16.5965 35.2177 16.7768 35.2256 16.9821 35.2256C17.1861 35.2256 17.3691 35.2177 17.5257 35.1993C17.581 35.3546 17.6442 35.4994 17.714 35.6389C17.7824 35.7745 17.8995 35.9732 18.0522 36.2352L18.3787 36.8011C18.6696 37.2882 18.9262 37.7067 19.1553 38.0595L19.1539 38.0516C19.3698 37.7199 19.6107 37.3263 19.8713 36.8735L20.2412 36.2352C20.3781 36.0074 20.482 35.8192 20.5492 35.681C20.6163 35.5441 20.6834 35.3848 20.7492 35.1993C20.8638 35.2177 20.9941 35.2256 21.1362 35.2256C21.2823 35.2256 21.41 35.2177 21.5219 35.1993C21.4929 35.2295 21.4324 35.3151 21.3363 35.452C21.2402 35.5915 21.0652 35.8508 20.8111 36.2352L20.3017 37.0078C19.9542 37.5488 19.6568 38.0253 19.408 38.4386V38.9137C19.408 39.5587 19.4106 40.022 19.4198 40.2958C19.429 40.5709 19.4435 40.7999 19.4738 40.9829C19.3422 40.9684 19.1947 40.9579 19.0302 40.9579C18.8657 40.9579 18.7209 40.9684 18.5893 40.9829C18.6169 40.7999 18.6314 40.5709 18.6419 40.2958C18.6485 40.022 18.6551 39.5587 18.6551 38.9137V38.6097Z"
                fill="white"
            />
            <path
                d="M25.5341 40.7068C24.8194 40.7068 24.2481 40.4765 23.8177 40.0119C23.3886 39.5485 23.1701 38.9325 23.1701 38.1599C23.1701 37.3464 23.3886 36.6975 23.8177 36.2079C24.2481 35.7195 24.8194 35.4747 25.5341 35.4747C26.2541 35.4747 26.8306 35.7064 27.2597 36.1697C27.6875 36.6304 27.9034 37.249 27.9034 38.023C27.9034 38.8364 27.6875 39.488 27.2597 39.975C26.8306 40.4646 26.2541 40.7068 25.5341 40.7068ZM25.4867 41.0714C26.4779 41.0714 27.2781 40.7871 27.8928 40.2172C28.5088 39.6512 28.8142 38.9075 28.8142 37.9901C28.8142 37.1332 28.5167 36.4422 27.9218 35.9078C27.3255 35.3773 26.545 35.1127 25.5815 35.1127C24.5943 35.1127 23.794 35.3984 23.1806 35.9657C22.5659 36.5382 22.2579 37.278 22.2579 38.1915C22.2579 39.0484 22.5567 39.7447 23.153 40.2738C23.7519 40.8042 24.5285 41.0714 25.4867 41.0714Z"
                fill="white"
            />
            <path
                d="M35.3517 38.4553C35.3517 39.3122 35.1358 39.9598 34.7081 40.4034C34.2816 40.8483 33.6524 41.0707 32.8258 41.0707C31.9874 41.0707 31.3635 40.8825 30.9607 40.5021C30.5566 40.1217 30.3552 39.5347 30.3526 38.7409V38.1499L30.3381 36.2308C30.3315 35.8926 30.3262 35.6556 30.317 35.5161C30.3104 35.3792 30.3025 35.2739 30.2841 35.1989C30.421 35.2173 30.5724 35.2265 30.7409 35.2265C30.9107 35.2265 31.062 35.2173 31.195 35.1989C31.1805 35.2766 31.166 35.3845 31.1594 35.524C31.1502 35.6596 31.141 35.8965 31.1292 36.2308C31.112 36.7981 31.1028 37.4352 31.1028 38.1407V38.4843C31.1055 39.2109 31.2608 39.7558 31.5648 40.1046C31.8689 40.4573 32.3375 40.6324 32.964 40.6324C33.6261 40.6324 34.1065 40.4468 34.4119 40.0756C34.7173 39.7044 34.866 39.1134 34.8621 38.3105V38.146L34.8515 36.2308C34.8463 35.8833 34.8423 35.6385 34.8384 35.5003C34.8357 35.3621 34.8305 35.2621 34.8239 35.1989C34.9187 35.2173 35.0226 35.2265 35.1385 35.2265C35.2556 35.2265 35.3636 35.2173 35.4557 35.1989C35.4346 35.2937 35.4202 35.4134 35.4109 35.549C35.3991 35.6885 35.3873 35.9176 35.3754 36.2308C35.3596 36.7981 35.3517 37.4352 35.3517 38.1434V38.4553Z"
                fill="white"
            />
            <path
                d="M37.9967 35.6171C38.2191 35.6027 38.4653 35.5908 38.7325 35.5882C39.1945 35.5882 39.5473 35.6777 39.7947 35.8501C40.0395 36.0252 40.1619 36.2753 40.1619 36.6057C40.1619 36.9571 40.0014 37.2427 39.6815 37.4533C39.3617 37.6666 38.9339 37.7692 38.3995 37.7692C38.26 37.7679 38.1244 37.7732 37.9967 37.7824V35.6171ZM37.2043 40.694C37.1964 40.8361 37.1859 40.9322 37.1754 40.9809C37.3083 40.9664 37.457 40.9572 37.6255 40.9572C37.7927 40.9572 37.944 40.9664 38.073 40.9809C38.0638 40.9282 38.0533 40.8322 38.048 40.694C38.0415 40.5584 38.0322 40.3096 38.0204 39.9489C38.0112 39.4159 38.0046 38.813 38.0046 38.1483H37.9967H38.2929C38.5956 38.4866 38.9365 38.8867 39.3169 39.3487L39.8092 39.9489C40.0422 40.2254 40.212 40.4333 40.3146 40.5715C40.4186 40.7084 40.5081 40.8453 40.5818 40.9809C40.7424 40.9664 40.9293 40.9572 41.1347 40.9572C41.3413 40.9572 41.5256 40.9664 41.6849 40.9809C41.6493 40.9546 41.573 40.869 41.4479 40.7334C41.3229 40.5939 41.0991 40.3346 40.7622 39.9489L40.287 39.3895C39.8711 38.9012 39.5143 38.4708 39.2182 38.093C39.642 38.0259 40.0106 37.8982 40.3186 37.7113C40.7951 37.4178 41.0359 37.0361 41.0359 36.5648C41.0359 36.1107 40.8727 35.7685 40.5502 35.5395C40.2264 35.3118 39.7407 35.1986 39.0918 35.1986C38.9878 35.1986 38.8694 35.1999 38.7351 35.2052C38.6035 35.2078 38.4574 35.2144 38.2929 35.2275C38.1915 35.2288 38.048 35.2288 37.8638 35.2275C37.6755 35.2236 37.4491 35.2131 37.1754 35.1986C37.1859 35.2525 37.1964 35.346 37.203 35.4842C37.2069 35.6237 37.2175 35.8725 37.228 36.2318C37.2385 36.782 37.2438 37.4007 37.2438 38.093C37.2438 38.7762 37.2385 39.3922 37.228 39.9476C37.2175 40.3096 37.2109 40.5584 37.2043 40.694Z"
                fill="white"
            />
            <path
                d="M46.1576 35.6478C46.3827 35.6254 46.6354 35.6136 46.9171 35.6136C47.3976 35.6136 47.7622 35.707 48.0083 35.8874C48.2544 36.0703 48.3782 36.3415 48.3782 36.6995C48.3782 37.0786 48.2307 37.38 47.9346 37.609C47.6345 37.8341 47.2396 37.9473 46.7421 37.9473C46.5341 37.9473 46.338 37.9394 46.1576 37.9223V35.6478ZM45.3666 40.693C45.3574 40.8299 45.3481 40.93 45.3389 40.9839C45.4706 40.9681 45.6206 40.9589 45.7865 40.9589C45.9563 40.9589 46.105 40.9681 46.2379 40.9839C46.2261 40.9313 46.2169 40.8365 46.2103 40.697C46.205 40.5601 46.1945 40.3113 46.184 39.9493C46.1708 39.4584 46.1642 38.9069 46.1669 38.2988H46.1576C46.3867 38.3303 46.642 38.3435 46.929 38.3382C47.6674 38.3382 48.24 38.1921 48.6427 37.8986C49.0481 37.6051 49.2508 37.1918 49.2508 36.6547C49.2508 36.1611 49.0784 35.7965 48.7375 35.557C48.394 35.3174 47.8688 35.199 47.1567 35.199C47.0474 35.199 46.9263 35.2029 46.7987 35.2055C46.667 35.2082 46.5249 35.2148 46.3722 35.224C46.2537 35.2306 46.1063 35.2345 45.9352 35.2345C45.7654 35.2345 45.5653 35.2306 45.3389 35.224C45.3481 35.2806 45.3574 35.3753 45.3666 35.5135C45.3718 35.6518 45.381 35.9005 45.3929 36.2586C45.4021 36.8061 45.4087 37.4208 45.4047 38.096C45.4047 38.7792 45.4021 39.3952 45.3929 39.9493C45.381 40.3074 45.3718 40.5548 45.3666 40.693Z"
                fill="white"
            />
            <path
                d="M50.2738 41.0205C50.2857 40.9678 50.2949 40.8704 50.3001 40.7322C50.3054 40.594 50.3146 40.3479 50.3265 39.9885C50.3357 39.4265 50.3396 38.7947 50.3396 38.0892C50.3396 37.3823 50.3357 36.7505 50.3265 36.1924C50.3146 35.837 50.3054 35.5869 50.3001 35.4501C50.2949 35.3132 50.2857 35.2171 50.2738 35.1605C50.3778 35.1802 50.5489 35.1947 50.7898 35.2079C51.032 35.2197 51.2755 35.225 51.5216 35.225H52.1903C52.4562 35.225 52.6957 35.2197 52.9063 35.2079C53.1196 35.1947 53.3038 35.175 53.4578 35.1486L53.4315 35.4408L53.4578 35.7265C53.3578 35.7054 53.1827 35.687 52.9274 35.6699C52.6747 35.6593 52.4285 35.6501 52.1903 35.6501H51.8783C51.6085 35.6475 51.3558 35.6528 51.1188 35.662L51.0951 37.7614L51.8744 37.7588C52.1337 37.7588 52.3996 37.7509 52.672 37.7364C52.9419 37.7219 53.1327 37.7061 53.2328 37.6837L53.2091 37.9733L53.2328 38.259C53.1327 38.2379 52.9419 38.2221 52.672 38.2063C52.3996 38.1931 52.1337 38.1852 51.8744 38.1852L51.0978 38.1826C51.0951 39.0803 51.1017 39.8608 51.1188 40.5216L51.7125 40.5295H52.0086C52.2745 40.5295 52.5575 40.5216 52.8445 40.5045C53.1393 40.4847 53.3407 40.465 53.4578 40.4439C53.4407 40.5295 53.4315 40.6282 53.4315 40.7414L53.4578 41.031C53.3341 41.0126 53.1472 40.9941 52.8918 40.9783C52.6378 40.9652 52.4022 40.9573 52.1903 40.9573H51.5216C51.2965 40.9573 51.0622 40.9652 50.8187 40.9757C50.5792 40.9876 50.3988 41.0033 50.2738 41.0205Z"
                fill="white"
            />
            <path
                d="M55.8189 35.6171C56.0413 35.6027 56.2875 35.5908 56.5573 35.5882C57.0206 35.5882 57.3694 35.6777 57.6169 35.8501C57.8617 36.0252 57.9855 36.2753 57.9855 36.6057C57.9855 36.9571 57.8262 37.2427 57.5063 37.4533C57.1852 37.6666 56.7574 37.7692 56.2217 37.7692C56.0821 37.7679 55.9505 37.7732 55.8189 37.7824V35.6171ZM55.0265 40.694C55.0186 40.8361 55.0094 40.9322 55.0002 40.9809C55.1305 40.9664 55.2819 40.9572 55.4477 40.9572C55.6149 40.9572 55.7662 40.9664 55.8992 40.9809C55.8873 40.9282 55.8781 40.8322 55.8715 40.694C55.8636 40.5584 55.8558 40.3096 55.8465 39.9489C55.8334 39.4159 55.8281 38.813 55.8281 38.1483H55.8189H56.1164C56.4217 38.4866 56.7587 38.8867 57.1404 39.3487L57.634 39.9489C57.867 40.2254 58.0368 40.4333 58.1381 40.5715C58.2408 40.7084 58.3316 40.8453 58.404 40.9809C58.5672 40.9664 58.7515 40.9572 58.9595 40.9572C59.1635 40.9572 59.3478 40.9664 59.511 40.9809C59.4755 40.9546 59.3952 40.869 59.2741 40.7334C59.149 40.5939 58.9213 40.3346 58.5844 39.9489L58.1092 39.3895C57.6932 38.9012 57.3365 38.4708 57.0417 38.093C57.4682 38.0259 57.8328 37.8982 58.1408 37.7113C58.6212 37.4178 58.8581 37.0361 58.8581 36.5648C58.8581 36.1107 58.6962 35.7685 58.3724 35.5395C58.05 35.3118 57.5629 35.1986 56.914 35.1986C56.81 35.1986 56.6916 35.1999 56.5599 35.2052C56.427 35.2078 56.2809 35.2144 56.1164 35.2275C56.015 35.2288 55.8715 35.2288 55.686 35.2275C55.503 35.2236 55.274 35.2131 55.0002 35.1986C55.0094 35.2525 55.0186 35.346 55.0252 35.4842C55.0331 35.6237 55.0423 35.8725 55.0502 36.2318C55.0634 36.782 55.066 37.4007 55.066 38.093C55.066 38.7762 55.0634 39.3922 55.0502 39.9476C55.0423 40.3096 55.0331 40.5584 55.0265 40.694Z"
                fill="white"
            />
            <path
                d="M60.4891 39.9503C60.4983 39.3962 60.5022 38.7696 60.5022 38.072C60.5022 37.3757 60.4983 36.7518 60.4891 36.195C60.4772 35.837 60.468 35.5882 60.4614 35.4487C60.4535 35.3144 60.4443 35.217 60.4364 35.1618C60.5404 35.1802 60.7352 35.1947 61.0182 35.2091C61.3038 35.2197 61.6013 35.2263 61.9067 35.2263H62.4437C62.7135 35.2263 62.9491 35.2197 63.1611 35.2091C63.3717 35.1947 63.5559 35.1762 63.7113 35.1499L63.6902 35.4395L63.7113 35.7278C63.6152 35.7054 63.4401 35.6869 63.1874 35.6712C62.932 35.658 62.6859 35.6501 62.4437 35.6501H62.0896C61.8027 35.6475 61.5394 35.6527 61.2893 35.6619C61.2749 36.2464 61.267 36.9308 61.263 37.7166L62.1554 37.7087C62.4226 37.7087 62.6925 37.7021 62.9662 37.6877C63.2361 37.6719 63.419 37.6534 63.5138 37.635L63.4875 37.9246L63.5138 38.2102C63.4427 38.1944 63.2716 38.1826 63.0005 38.1655C62.728 38.151 62.4463 38.1418 62.1554 38.1352L61.263 38.122C61.263 38.7999 61.2683 39.4106 61.2814 39.9503C61.2933 40.3096 61.3025 40.5611 61.3078 40.6966C61.313 40.8375 61.3222 40.9322 61.3341 40.9823C61.2011 40.9665 61.0524 40.9572 60.8839 40.9572C60.7181 40.9572 60.5693 40.9665 60.4364 40.9823C60.4443 40.9283 60.4575 40.8309 60.4654 40.69C60.4719 40.5545 60.4772 40.3096 60.4891 39.9503Z"
                fill="white"
            />
            <path
                d="M64.9907 41.0205C64.9999 40.9678 65.0078 40.8704 65.0144 40.7322C65.0223 40.594 65.0328 40.3479 65.042 39.9885C65.0499 39.4265 65.0565 38.7947 65.0565 38.0892C65.0565 37.3823 65.0499 36.7505 65.042 36.1924C65.0328 35.837 65.0223 35.5869 65.0144 35.4501C65.0078 35.3132 64.9999 35.2171 64.9907 35.1605C65.092 35.1802 65.2631 35.1947 65.5066 35.2079C65.7462 35.2197 65.9897 35.225 66.2385 35.225H66.9071C67.173 35.225 67.4126 35.2197 67.6245 35.2079C67.8377 35.1947 68.0181 35.175 68.1747 35.1486L68.1484 35.4408L68.1747 35.7265C68.072 35.7054 67.8956 35.687 67.6416 35.6699C67.3902 35.6593 67.1441 35.6501 66.9071 35.6501H66.5952C66.3253 35.6475 66.0713 35.6528 65.8357 35.662L65.8094 37.7614L66.5886 37.7588C66.8492 37.7588 67.1138 37.7509 67.3863 37.7364C67.6587 37.7219 67.8469 37.7061 67.9509 37.6837L67.9233 37.9733L67.9509 38.259C67.8469 38.2379 67.6587 38.2221 67.3863 38.2063C67.1138 38.1931 66.8492 38.1852 66.5886 38.1852L65.812 38.1826C65.8094 39.0803 65.8186 39.8608 65.8357 40.5216L66.4293 40.5295H66.7255C66.9914 40.5295 67.2704 40.5216 67.5613 40.5045C67.8522 40.4847 68.0575 40.465 68.1747 40.4439C68.1576 40.5295 68.1484 40.6282 68.1484 40.7414L68.1747 41.031C68.051 41.0126 67.8601 40.9941 67.6061 40.9783C67.3507 40.9652 67.1164 40.9573 66.9071 40.9573H66.2385C66.0108 40.9573 65.7765 40.9652 65.5356 40.9757C65.2921 40.9876 65.1118 41.0033 64.9907 41.0205Z"
                fill="white"
            />
            <path
                d="M74.4677 40.1172L74.4216 40.362L74.3939 40.6121C74.0886 40.7713 73.7766 40.8872 73.4555 40.9609C73.1369 41.0333 72.7802 41.0714 72.3906 41.0714C71.4758 41.0714 70.7374 40.8029 70.1688 40.2725C69.6015 39.7407 69.3171 39.0457 69.3171 38.1888C69.3171 37.2727 69.6199 36.5317 70.2214 35.9657C70.8243 35.3957 71.6114 35.1127 72.5788 35.1127C72.9526 35.1127 73.2962 35.1404 73.6147 35.2036C73.9319 35.2615 74.2492 35.3576 74.5637 35.4826C74.519 35.6156 74.4808 35.7314 74.4505 35.8288C74.4242 35.9262 74.3939 36.0407 74.361 36.1789L74.2189 36.1881C74.0267 35.9709 73.7832 35.7985 73.4857 35.6695C73.1962 35.5405 72.896 35.4747 72.5946 35.4747C71.8786 35.4747 71.306 35.7182 70.8769 36.2105C70.4465 36.6975 70.2306 37.3477 70.2306 38.1625C70.2306 38.8996 70.4504 39.5077 70.8848 39.9882C71.3244 40.466 71.8759 40.7068 72.5472 40.7068C72.9 40.7068 73.237 40.6463 73.57 40.5252C73.8951 40.4015 74.1715 40.2356 74.3939 40.025L74.4677 40.1172Z"
                fill="white"
            />
            <path
                d="M77.0982 39.9529C77.1048 39.3988 77.1113 38.7815 77.1113 38.1023C77.1127 37.1519 77.1048 36.3332 77.0837 35.6461L76.6178 35.6501C76.3295 35.6501 76.0425 35.6593 75.753 35.6738C75.4647 35.6883 75.2686 35.7067 75.1672 35.7278L75.1949 35.4369L75.1672 35.1499C75.4884 35.171 75.853 35.1868 76.2597 35.2026C76.6651 35.217 76.9771 35.2263 77.1956 35.2263H77.4273H77.6589C77.8919 35.2263 78.2157 35.217 78.6224 35.2026C79.0252 35.1868 79.3819 35.171 79.6873 35.1499L79.6596 35.4369L79.6873 35.7278C79.5793 35.7067 79.3845 35.6883 79.0936 35.6738C78.8067 35.6593 78.5184 35.6501 78.2368 35.6501H77.9051C77.884 36.3332 77.8748 37.1506 77.8748 38.0983C77.8748 38.7854 77.8814 39.4041 77.8906 39.9529C77.9024 40.3096 77.9116 40.5571 77.9169 40.694C77.9222 40.8335 77.9301 40.9296 77.9432 40.9823C77.8103 40.9678 77.6615 40.9572 77.4931 40.9572C77.3272 40.9572 77.1745 40.9678 77.0455 40.9823C77.0534 40.9296 77.0666 40.8309 77.0705 40.6927C77.0771 40.5571 77.0863 40.3096 77.0982 39.9529Z"
                fill="white"
            />
            <path
                d="M84.283 35.6869C84.4883 35.6501 84.7094 35.6356 84.9516 35.6369C85.386 35.6369 85.7335 35.7238 85.9941 35.9002C86.2547 36.0726 86.3824 36.3043 86.3824 36.5925C86.3824 36.9255 86.2442 37.1927 85.9586 37.3955C85.6769 37.5955 85.2978 37.6956 84.8266 37.6956H84.283V35.6869ZM83.4919 40.6966C83.4827 40.8335 83.4735 40.9322 83.4616 40.9823L83.892 40.9572L85.0095 40.9823C85.7335 40.9823 86.3166 40.8309 86.768 40.5281C87.2156 40.2241 87.442 39.8279 87.442 39.3422C87.442 38.8289 87.2485 38.4471 86.8602 38.1931C86.5983 38.0207 86.2521 37.9048 85.819 37.8482C86.1257 37.7798 86.389 37.6732 86.618 37.5231C87.0353 37.2493 87.2432 36.894 87.2432 36.457C87.2432 36.0358 87.0695 35.7238 86.7233 35.5145C86.3771 35.3052 85.8546 35.1999 85.1569 35.1999L83.892 35.2249L83.4616 35.1999C83.4735 35.2526 83.4827 35.3434 83.4893 35.4856C83.4971 35.6211 83.5064 35.8725 83.5143 36.2345C83.5274 36.7794 83.534 37.4007 83.5301 38.0918C83.5301 38.7736 83.5248 39.3922 83.5143 39.9503C83.5064 40.3083 83.4971 40.5584 83.4919 40.6966ZM84.283 38.122H84.87C85.4334 38.122 85.8611 38.2168 86.1481 38.4077C86.439 38.5946 86.5811 38.8802 86.5811 39.2514C86.5811 39.6805 86.4337 40.0003 86.1362 40.2175C85.8427 40.4347 85.4044 40.5439 84.8279 40.5439C84.6357 40.5439 84.4528 40.5308 84.283 40.5071V38.122Z"
                fill="white"
            />
            <path
                d="M90.9971 36.4095L91.7592 38.0799L91.8461 38.2773C91.5789 38.3089 91.2907 38.3286 90.9826 38.3352C90.6681 38.3405 90.3838 38.3247 90.1231 38.2865L90.2179 38.072C90.493 37.4586 90.6325 37.1532 90.6325 37.1532C90.6325 37.1532 90.7536 36.9031 90.9971 36.4095ZM88.4739 40.7163C88.3988 40.8559 88.3462 40.9467 88.3185 40.9809C88.4199 40.9677 88.5384 40.9572 88.6687 40.9572C88.8003 40.9572 88.9188 40.9677 89.0214 40.9809C89.0635 40.8164 89.1083 40.6637 89.1557 40.5255C89.2057 40.3859 89.2834 40.1951 89.39 39.9489C89.5321 39.5988 89.698 39.2224 89.8796 38.8262C90.2021 38.763 90.5733 38.7393 90.9932 38.7538C91.4012 38.7604 91.7658 38.7841 92.0896 38.8262L92.5845 39.9489C92.703 40.2161 92.7859 40.4189 92.8373 40.5584C92.8873 40.6966 92.9307 40.8401 92.9702 40.9809C93.1084 40.9677 93.269 40.9572 93.4533 40.9572C93.6349 40.9572 93.7942 40.9677 93.9363 40.9809L93.4269 39.9489C93.1624 39.404 92.8781 38.7933 92.5674 38.1115C92.0396 36.9637 91.6131 36.0041 91.288 35.2249H91.1801H91.0774C90.7536 36.0081 90.3179 36.9742 89.773 38.1207C89.4518 38.7999 89.1531 39.408 88.8766 39.9489C88.6831 40.3241 88.5476 40.5781 88.4739 40.7163Z"
                fill="white"
            />
            <path
                d="M95.7127 39.9506C95.7271 40.2994 95.7403 40.5442 95.7482 40.6824C95.7561 40.822 95.7679 40.9194 95.7798 40.9825C95.689 40.9667 95.5823 40.9575 95.4626 40.9575C95.3454 40.9575 95.2388 40.9667 95.1467 40.9825C95.1585 40.9286 95.1677 40.8351 95.173 40.6943C95.1783 40.5561 95.1875 40.3099 95.1993 39.9506C95.2072 39.3964 95.2112 38.7765 95.2112 38.096C95.2112 37.4155 95.2072 36.8047 95.1993 36.2585C95.1875 35.9005 95.1783 35.653 95.173 35.5148C95.1677 35.3766 95.1585 35.2792 95.1467 35.2265H95.2862H95.4218C96.1668 36.0334 97.1171 37.0206 98.2807 38.1881L99.722 39.6202L99.7233 38.1486C99.7233 37.4392 99.7193 36.8008 99.7075 36.2322C99.6943 35.8728 99.6864 35.624 99.6812 35.4845C99.6746 35.3476 99.6654 35.2529 99.6535 35.2002C99.7457 35.2173 99.847 35.2265 99.9655 35.2265C100.08 35.2265 100.184 35.2173 100.276 35.2002C100.264 35.2529 100.256 35.3476 100.25 35.4845C100.239 35.624 100.235 35.8728 100.222 36.2322C100.214 36.7982 100.208 37.4352 100.208 38.1447C100.208 39.3122 100.222 40.2876 100.246 41.0707H100.119H99.9918C99.2481 40.2652 98.2952 39.278 97.1329 38.1078L95.685 36.6652V38.0933C95.685 38.7831 95.6982 39.4017 95.7127 39.9506Z"
                fill="white"
            />
            <path
                d="M102.948 39.947C102.96 40.3063 102.969 40.5551 102.977 40.6946C102.98 40.8302 102.99 40.9289 103.001 40.9829C102.868 40.9671 102.719 40.9579 102.551 40.9579C102.387 40.9579 102.236 40.9671 102.103 40.9829C102.115 40.9316 102.124 40.8355 102.129 40.6973C102.135 40.5591 102.144 40.3077 102.156 39.9496C102.165 39.3942 102.169 38.7742 102.169 38.0924C102.169 37.4027 102.165 36.7814 102.156 36.2352C102.144 35.8719 102.135 35.6218 102.129 35.4862C102.124 35.3467 102.115 35.2532 102.103 35.1993C102.236 35.2164 102.387 35.2243 102.551 35.2243C102.719 35.2243 102.868 35.2164 103.001 35.1993C102.99 35.2545 102.98 35.3506 102.977 35.4888C102.969 35.6257 102.96 35.8758 102.948 36.2325C102.936 36.734 102.934 37.2776 102.936 37.8686H102.948C103.336 37.5514 103.791 37.1394 104.304 36.6379C104.866 36.0904 105.313 35.6218 105.656 35.2243H106.115C106.286 35.2243 106.441 35.2164 106.574 35.1993C106.515 35.2361 106.406 35.3204 106.248 35.4586C106.085 35.5941 105.791 35.8548 105.365 36.2325L104.647 36.8854L103.725 37.7594L104.682 38.8558L105.665 39.947C106.014 40.3366 106.255 40.6012 106.38 40.7394C106.507 40.8789 106.586 40.9579 106.617 40.9829C106.453 40.9671 106.269 40.9579 106.061 40.9579C105.85 40.9579 105.665 40.9671 105.502 40.9829C105.416 40.8394 105.321 40.7012 105.211 40.563C105.098 40.4274 104.923 40.2221 104.676 39.947L104.22 39.431C103.718 38.8729 103.292 38.4122 102.934 38.0437H102.936L102.931 38.0871C102.931 38.7768 102.936 39.3968 102.948 39.947Z"
                fill="white"
            />
            <path
                d="M107.684 39.9484C107.692 39.3929 107.698 38.7769 107.698 38.0937C107.698 37.4014 107.692 36.7801 107.684 36.2352C107.674 35.8745 107.667 35.6284 107.66 35.4902C107.651 35.3507 107.642 35.2546 107.631 35.1993C107.764 35.2177 107.915 35.2269 108.08 35.2269C108.248 35.2269 108.398 35.2177 108.533 35.1993C108.52 35.2546 108.512 35.3507 108.505 35.4902C108.498 35.6284 108.491 35.8745 108.477 36.2352C108.469 36.7854 108.467 37.4053 108.467 38.0885C108.467 38.7795 108.469 39.3995 108.477 39.9484C108.491 40.3064 108.498 40.5551 108.505 40.6947C108.512 40.8316 108.52 40.9276 108.533 40.9816C108.398 40.9685 108.248 40.9579 108.08 40.9579C107.915 40.9579 107.764 40.9685 107.631 40.9816C107.642 40.9276 107.655 40.8316 107.66 40.6934C107.667 40.5551 107.674 40.3064 107.684 39.9484Z"
                fill="white"
            />
            <path
                d="M110.913 39.9506C110.926 40.2994 110.936 40.5442 110.946 40.6824C110.955 40.822 110.965 40.9194 110.979 40.9825C110.886 40.9667 110.78 40.9575 110.661 40.9575C110.542 40.9575 110.439 40.9667 110.344 40.9825C110.355 40.9286 110.365 40.8351 110.369 40.6943C110.378 40.5561 110.389 40.3099 110.397 39.9506C110.406 39.3964 110.409 38.7765 110.409 38.096C110.409 37.4155 110.406 36.8047 110.397 36.2585C110.389 35.9005 110.378 35.653 110.369 35.5148C110.365 35.3766 110.355 35.2792 110.344 35.2265H110.484H110.619C111.363 36.0334 112.316 37.0206 113.477 38.1881L114.919 39.6202L114.925 38.1486C114.925 37.4392 114.919 36.8008 114.905 36.2322C114.894 35.8728 114.888 35.624 114.877 35.4845C114.872 35.3476 114.867 35.2529 114.854 35.2002C114.943 35.2173 115.047 35.2265 115.162 35.2265C115.279 35.2265 115.385 35.2173 115.474 35.2002C115.462 35.2529 115.453 35.3476 115.446 35.4845C115.442 35.624 115.433 35.8728 115.421 36.2322C115.41 36.7982 115.406 37.4352 115.406 38.1447C115.406 39.3122 115.421 40.2876 115.446 41.0707H115.321H115.192C114.448 40.2652 113.497 39.278 112.333 38.1078L110.882 36.6652V38.0933C110.886 38.7831 110.896 39.4017 110.913 39.9506Z"
                fill="white"
            />
            <path
                d="M120.091 41.0714C119.138 41.0714 118.361 40.8029 117.769 40.2725C117.175 39.7433 116.88 39.0497 116.88 38.1915C116.88 37.2767 117.184 36.5356 117.795 35.967C118.406 35.3971 119.201 35.1114 120.183 35.1114C120.639 35.1114 121.061 35.1549 121.449 35.2365C121.835 35.3233 122.199 35.455 122.549 35.6287C122.505 35.7617 122.466 35.8762 122.436 35.9736C122.406 36.071 122.377 36.1894 122.345 36.3303L122.202 36.3316C121.958 36.071 121.669 35.8643 121.317 35.7077C120.974 35.5537 120.628 35.4747 120.284 35.4747C119.531 35.4747 118.926 35.7195 118.472 36.2092C118.017 36.6975 117.79 37.3504 117.79 38.1678C117.79 38.9365 118.017 39.5538 118.476 40.0145C118.928 40.4765 119.535 40.7068 120.289 40.7068C120.779 40.7068 121.265 40.6147 121.754 40.4278V39.846C121.754 39.4735 121.749 39.1826 121.737 38.9773C121.724 38.7706 121.698 38.5455 121.662 38.2981C121.796 38.3165 121.95 38.3218 122.124 38.3218C122.297 38.3218 122.451 38.3165 122.585 38.2981C122.564 38.3929 122.549 38.5113 122.538 38.6482C122.528 38.7864 122.522 39.0128 122.516 39.33V39.4814C122.516 39.8657 122.519 40.1422 122.526 40.3054C122.534 40.4686 122.549 40.6055 122.573 40.7121C121.854 40.9503 121.028 41.0714 120.091 41.0714Z"
                fill="white"
            />
            <path
                d="M127.727 35.6478C127.952 35.6254 128.204 35.6136 128.482 35.6136C128.964 35.6136 129.329 35.707 129.577 35.8874C129.823 36.0703 129.947 36.3415 129.947 36.6995C129.947 37.0786 129.8 37.38 129.501 37.609C129.203 37.8341 128.806 37.9473 128.311 37.9473C128.103 37.9473 127.907 37.9394 127.727 37.9223V35.6478ZM126.934 40.693C126.926 40.8299 126.917 40.93 126.905 40.9839C127.038 40.9681 127.187 40.9589 127.355 40.9589C127.523 40.9589 127.67 40.9681 127.803 40.9839C127.795 40.9313 127.786 40.8365 127.778 40.697C127.771 40.5601 127.762 40.3113 127.75 39.9493C127.735 39.4584 127.733 38.9069 127.735 38.2988H127.723C127.952 38.3303 128.207 38.3435 128.495 38.3382C129.232 38.3382 129.805 38.1921 130.208 37.8986C130.614 37.6051 130.817 37.1918 130.817 36.6547C130.817 36.1611 130.647 35.7965 130.306 35.557C129.962 35.3174 129.435 35.199 128.723 35.199C128.614 35.199 128.495 35.2029 128.364 35.2055C128.236 35.2082 128.094 35.2148 127.937 35.224C127.819 35.2306 127.675 35.2345 127.504 35.2345C127.332 35.2345 127.133 35.2306 126.905 35.224C126.917 35.2806 126.926 35.3753 126.933 35.5135C126.937 35.6518 126.947 35.9005 126.958 36.2586C126.971 36.8061 126.974 37.4208 126.974 38.096C126.974 38.7792 126.968 39.3952 126.958 39.9493C126.947 40.3074 126.937 40.5548 126.934 40.693Z"
                fill="white"
            />
            <path
                d="M133.978 36.4095L134.744 38.0799L134.829 38.2773C134.562 38.3089 134.271 38.3286 133.963 38.3352C133.648 38.3405 133.364 38.3247 133.102 38.2865L133.198 38.072C133.477 37.4586 133.613 37.1532 133.613 37.1532C133.613 37.1532 133.735 36.9031 133.978 36.4095ZM131.454 40.7163C131.379 40.8559 131.329 40.9467 131.299 40.9809C131.403 40.9677 131.52 40.9572 131.649 40.9572C131.783 40.9572 131.902 40.9677 132.003 40.9809C132.044 40.8164 132.089 40.6637 132.14 40.5255C132.186 40.3859 132.264 40.1951 132.37 39.9489C132.513 39.5988 132.678 39.2224 132.861 38.8262C133.186 38.763 133.558 38.7393 133.975 38.7538C134.38 38.7604 134.748 38.7841 135.073 38.8262L135.568 39.9489C135.683 40.2161 135.769 40.4189 135.82 40.5584C135.866 40.6966 135.911 40.8401 135.951 40.9809C136.089 40.9677 136.252 40.9572 136.434 40.9572C136.614 40.9572 136.777 40.9677 136.919 40.9809L136.407 39.9489C136.144 39.404 135.857 38.7933 135.547 38.1115C135.021 36.9637 134.595 36.0041 134.268 35.2249H134.162H134.058C133.735 36.0081 133.301 36.9742 132.753 38.1207C132.432 38.7999 132.133 39.408 131.857 39.9489C131.664 40.3241 131.528 40.5781 131.454 40.7163Z"
                fill="white"
            />
            <path
                d="M138.856 35.6171C139.078 35.6027 139.324 35.5908 139.591 35.5882C140.053 35.5882 140.406 35.6777 140.654 35.8501C140.898 36.0252 141.021 36.2753 141.021 36.6057C141.021 36.9571 140.86 37.2427 140.54 37.4533C140.22 37.6666 139.793 37.7692 139.26 37.7692C139.116 37.7679 138.983 37.7732 138.856 37.7824V35.6171ZM138.063 40.694C138.055 40.8361 138.046 40.9322 138.034 40.9809C138.167 40.9664 138.316 40.9572 138.484 40.9572C138.652 40.9572 138.803 40.9664 138.933 40.9809C138.924 40.9282 138.915 40.8322 138.907 40.694C138.9 40.5584 138.891 40.3096 138.879 39.9489C138.87 39.4159 138.862 38.813 138.862 38.1483H138.852H139.153C139.454 38.4866 139.799 38.8867 140.178 39.3487L140.669 39.9489C140.901 40.2254 141.071 40.4333 141.173 40.5715C141.277 40.7084 141.368 40.8453 141.441 40.9809C141.603 40.9664 141.786 40.9572 141.993 40.9572C142.2 40.9572 142.384 40.9664 142.544 40.9809C142.508 40.9546 142.432 40.869 142.307 40.7334C142.182 40.5939 141.958 40.3346 141.621 39.9489L141.142 39.3895C140.727 38.9012 140.373 38.4708 140.078 38.093C140.502 38.0259 140.868 37.8982 141.177 37.7113C141.655 37.4178 141.892 37.0361 141.892 36.5648C141.892 36.1107 141.732 35.7685 141.409 35.5395C141.087 35.3118 140.6 35.1986 139.947 35.1986C139.847 35.1986 139.728 35.1999 139.594 35.2052C139.464 35.2078 139.316 35.2144 139.153 35.2275C139.052 35.2288 138.908 35.2288 138.723 35.2275C138.54 35.2236 138.308 35.2131 138.034 35.1986C138.046 35.2525 138.055 35.346 138.062 35.4842C138.066 35.6237 138.076 35.8725 138.087 36.2318C138.097 36.782 138.103 37.4007 138.103 38.093C138.103 38.7762 138.097 39.3922 138.087 39.9476C138.076 40.3096 138.066 40.5584 138.063 40.694Z"
                fill="white"
            />
            <path
                d="M144.925 39.9529C144.935 39.3988 144.938 38.7815 144.938 38.1023C144.94 37.1519 144.932 36.3332 144.911 35.6461L144.446 35.6501C144.157 35.6501 143.87 35.6593 143.58 35.6738C143.292 35.6883 143.096 35.7067 142.994 35.7278L143.022 35.4369L142.994 35.1499C143.318 35.171 143.68 35.1868 144.087 35.2026C144.492 35.217 144.804 35.2263 145.023 35.2263H145.254H145.486C145.719 35.2263 146.044 35.217 146.45 35.2026C146.852 35.1868 147.212 35.171 147.517 35.1499L147.488 35.4369L147.517 35.7278C147.408 35.7067 147.212 35.6883 146.921 35.6738C146.634 35.6593 146.346 35.6501 146.064 35.6501H145.732C145.715 36.3332 145.705 37.1506 145.705 38.0983C145.705 38.7854 145.709 39.4041 145.718 39.9529C145.73 40.3096 145.736 40.5571 145.744 40.694C145.751 40.8335 145.76 40.9296 145.77 40.9823C145.637 40.9678 145.49 40.9572 145.323 40.9572C145.154 40.9572 145.006 40.9678 144.873 40.9823C144.883 40.9296 144.894 40.8309 144.899 40.6927C144.904 40.5571 144.915 40.3096 144.925 39.9529Z"
                fill="white"
            />
            <path
                d="M149.317 39.9506C149.332 40.2994 149.341 40.5442 149.353 40.6824C149.362 40.822 149.37 40.9194 149.385 40.9825C149.289 40.9667 149.185 40.9575 149.067 40.9575C148.948 40.9575 148.842 40.9667 148.751 40.9825C148.762 40.9286 148.772 40.8351 148.776 40.6943C148.783 40.5561 148.793 40.3099 148.801 39.9506C148.81 39.3964 148.817 38.7765 148.817 38.096C148.817 37.4155 148.81 36.8047 148.801 36.2585C148.793 35.9005 148.783 35.653 148.776 35.5148C148.772 35.3766 148.762 35.2792 148.751 35.2265H148.887H149.024C149.77 36.0334 150.723 37.0206 151.881 38.1881L153.324 39.6202L153.329 38.1486C153.329 37.4392 153.324 36.8008 153.312 36.2322C153.299 35.8728 153.291 35.624 153.284 35.4845C153.276 35.3476 153.267 35.2529 153.258 35.2002C153.347 35.2173 153.45 35.2265 153.57 35.2265C153.686 35.2265 153.786 35.2173 153.878 35.2002C153.866 35.2529 153.857 35.3476 153.85 35.4845C153.845 35.624 153.836 35.8728 153.828 36.2322C153.819 36.7982 153.812 37.4352 153.812 38.1447C153.812 39.3122 153.828 40.2876 153.85 41.0707H153.724H153.596C152.851 40.2652 151.901 39.278 150.737 38.1078L149.287 36.6652V38.0933C149.289 38.7831 149.3 39.4017 149.317 39.9506Z"
                fill="white"
            />
            <path
                d="M155.536 41.0205C155.544 40.9678 155.553 40.8704 155.564 40.7322C155.568 40.594 155.578 40.3479 155.589 39.9885C155.598 39.4265 155.602 38.7947 155.602 38.0892C155.602 37.3823 155.598 36.7505 155.589 36.1924C155.578 35.837 155.568 35.5869 155.564 35.4501C155.553 35.3132 155.544 35.2171 155.536 35.1605C155.64 35.1802 155.811 35.1947 156.052 35.2079C156.291 35.2197 156.538 35.225 156.784 35.225H157.452C157.718 35.225 157.958 35.2197 158.172 35.2079C158.383 35.1947 158.566 35.175 158.72 35.1486L158.694 35.4408L158.72 35.7265C158.62 35.7054 158.441 35.687 158.188 35.6699C157.938 35.6593 157.692 35.6501 157.452 35.6501H157.14C156.871 35.6475 156.618 35.6528 156.38 35.662L156.353 37.7614L157.138 37.7588C157.395 37.7588 157.659 37.7509 157.932 37.7364C158.204 37.7219 158.391 37.7061 158.495 37.6837L158.469 37.9733L158.495 38.259C158.391 38.2379 158.204 38.2221 157.932 38.2063C157.659 38.1931 157.395 38.1852 157.138 38.1852L156.357 38.1826C156.357 39.0803 156.364 39.8608 156.38 40.5216L156.976 40.5295H157.269C157.537 40.5295 157.816 40.5216 158.107 40.5045C158.397 40.4847 158.602 40.465 158.72 40.4439C158.703 40.5295 158.694 40.6282 158.694 40.7414L158.72 41.031C158.595 41.0126 158.405 40.9941 158.154 40.9783C157.9 40.9652 157.664 40.9573 157.452 40.9573H156.784C156.555 40.9573 156.322 40.9652 156.084 40.9757C155.841 40.9876 155.661 41.0033 155.536 41.0205Z"
                fill="white"
            />
            <path
                d="M161.081 35.6171C161.303 35.6027 161.549 35.5908 161.819 35.5882C162.279 35.5882 162.631 35.6777 162.879 35.8501C163.124 36.0252 163.249 36.2753 163.249 36.6057C163.249 36.9571 163.088 37.2427 162.768 37.4533C162.447 37.6666 162.018 37.7692 161.485 37.7692C161.344 37.7679 161.211 37.7732 161.081 37.7824V35.6171ZM160.288 40.694C160.281 40.8361 160.271 40.9322 160.262 40.9809C160.392 40.9664 160.541 40.9572 160.71 40.9572C160.877 40.9572 161.028 40.9664 161.161 40.9809C161.149 40.9282 161.14 40.8322 161.132 40.694C161.126 40.5584 161.116 40.3096 161.108 39.9489C161.095 39.4159 161.089 38.813 161.089 38.1483H161.081H161.378C161.68 38.4866 162.023 38.8867 162.404 39.3487L162.895 39.9489C163.13 40.2254 163.299 40.4333 163.399 40.5715C163.504 40.7084 163.592 40.8453 163.666 40.9809C163.829 40.9664 164.013 40.9572 164.221 40.9572C164.425 40.9572 164.61 40.9664 164.773 40.9809C164.737 40.9546 164.657 40.869 164.536 40.7334C164.411 40.5939 164.183 40.3346 163.848 39.9489L163.371 39.3895C162.955 38.9012 162.598 38.4708 162.304 38.093C162.73 38.0259 163.095 37.8982 163.403 37.7113C163.883 37.4178 164.12 37.0361 164.12 36.5648C164.12 36.1107 163.957 35.7685 163.634 35.5395C163.312 35.3118 162.825 35.1986 162.176 35.1986C162.072 35.1986 161.953 35.1999 161.822 35.2052C161.689 35.2078 161.541 35.2144 161.378 35.2275C161.277 35.2288 161.133 35.2288 160.948 35.2275C160.765 35.2236 160.536 35.2131 160.262 35.1986C160.271 35.2525 160.281 35.346 160.288 35.4842C160.296 35.6237 160.304 35.8725 160.312 36.2318C160.323 36.782 160.328 37.4007 160.328 38.093C160.328 38.7762 160.323 39.3922 160.312 39.9476C160.304 40.3096 160.296 40.5584 160.288 40.694Z"
                fill="white"
            />
        </svg>
    );
};

export default FederalLogo;
