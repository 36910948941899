export const routeConstant = {
    DASHBOARD: 'dashboard',
    CUSTOMER_DASHBOARD: 'customerDashboard',
    LOGIN: 'login',
    SET_PASSWORD: 'setPassword',
    SET_PASSWORD_SUCCESS: 'success',
    LOAN_APPROVAL: 'loanApproval',
    RETRIGGER: 'retrigger',
    REPORTS: 'reports',
    PROSPECT_DETAILS: 'prospect-details',
    AUDIT: 'audit',
    PROSPECT_REPORT: 'prospect-report',
    CREDIT_REPORT: 'credit-report',

    DISBURSAL_REPORT: 'disbursal-report',
};
