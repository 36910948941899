import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: 0,
    data: null,
    loading: false,
    error: null,
    approveValue: [
        {
            id: 0,
            // icon: (color) => <House fill={color} />,
            type: false,
            value: 'House Verified',
        },
        {
            id: 1,
            // icon: (color) => <KYC fill={color} />,
            type: false,
            value: 'KYC Verified',
        },
        {
            id: 2,
            // icon: (color) => <ProspectData fill={color} />,
            type: false,
            value: 'Prospect Data Verified',
        },
        {
            id: 3,
            // icon: (color) => <MobileNumber fill={color} />,
            type: false,
            value: 'Mobile Number Verified',
        },
        {
            id: 4,
            // icon: (color) => <Loan fill={color} />,
            type: false,
            value: 'Loan Product Awareness',
        },
    ],
    rejectValue: [
        { id: 0, value: 'Mobile Number Switched Off', type: true },
        { id: 1, value: 'Incorrect Mobile Number', type: false },
        { id: 2, value: 'Incoming Call Barred', type: false },
        { id: 3, value: 'Incorrect KYC', type: false },
        { id: 4, value: 'KYC Image Not Available', type: false },
        { id: 5, value: 'Incorrect Communication Address', type: false },
        {
            id: 6,
            value: 'Incorrect Bank Account Number/ Account Name',
            type: false,
        },
        {
            id: 7,
            value: 'Bank IFSC Mismatch',
            type: false,
        },
        {
            id: 8,
            value: 'Incorrect Animal Details',
            type: false,
        },
        {
            id: 9,
            value: 'Incorrect Income Details',
            type: false,
        },
        {
            id: 10,
            value: 'Prospect Not Interested',
            type: false,
        },
        {
            id: 11,
            value: 'Prospect Migrated / Demised',
            type: false,
        },
        {
            id: 12,
            value: 'Prospect Issue With Group Members',
            type: false,
        },
        {
            id: 13,
            value: 'Keen Relation In Group',
            type: false,
        },
        {
            id: 14,
            value: 'GRT Not Conducted',
            type: false,
        },
        {
            id: 15,
            value: 'Lack Of Loan Product Awareness',
            type: false,
        },
        {
            id: 16,
            value: 'Product Involved In Suspicious Activity',
            type: false,
        },
        {
            id: 17,
            value: 'Ring Leader Involvement',
            type: false,
        },
        {
            id: 18,
            value: 'Other Reasons',
            type: false,
        },
    ],
};

export const auditSlice = createSlice({
    name: 'audit',
    initialState,
    reducers: {
        approveBoolCheck: (state, action) => {
            state.approveValue[action.payload].type =
                state.approveValue[action.payload].type === true ? false : true;
        },
        rejectBoolCheck: (state, action) => {
            state.rejectValue[action.payload].type =
                state.rejectValue[action.payload].type === true ? false : true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('audit/CLEAR_STATE', () => {
            return initialState;
        });
    },
});

// Action creators are generated for each case reducer function
export const { approveBoolCheck, rejectBoolCheck } = auditSlice.actions;

export default auditSlice.reducer;
