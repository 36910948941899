import * as React from 'react';
const Audit = (props) => (
    <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M29.7837 26.3645L26.0642 22.645C26.9306 21.1786 27.4877 19.9571 27.4877 18.2611C27.4877 14.2049 24.1999 10.917 20.1436 10.917C16.0873 10.917 12.7994 14.2049 12.7994 18.2611C12.7994 22.3174 16.1057 26.0002 20.1436 25.6053C21.7936 25.443 23.2845 25.1491 24.5274 24.1818L28.2469 27.9043C28.6051 28.2625 29.3062 28.1431 29.6643 27.785C30.0225 27.4237 30.1419 26.7227 29.7837 26.3645ZM14.9485 18.2611C14.9485 15.3927 17.2751 13.0661 20.1436 13.0661C23.0121 13.0661 25.3387 15.3927 25.3387 18.2611C25.3387 21.1296 23.0121 23.4562 20.1436 23.4562C17.2751 23.4562 14.9485 21.1296 14.9485 18.2611Z" />
        <path d="M22.8376 2.47356C22.8376 1.1082 21.7294 0 20.364 0H2.47356C1.10514 0.00306134 0 1.1082 0 2.47356V12.5821V12.9801V25.6479C0 27.0132 1.1082 28.1214 2.47356 28.1214H4.27669L4.29199 28.1031C4.35628 28.1153 4.42363 28.1214 4.49404 28.1214H13.3811C13.9781 28.1214 14.4617 27.6377 14.4617 27.0408C14.4617 26.4438 13.9781 25.9601 13.3811 25.9601H6.1043H4.49404H3.39808C2.7154 25.9601 2.1613 25.406 2.1613 24.7233V12.9801V12.5821V3.40114C2.1613 2.71847 2.7154 2.16436 3.39808 2.16436H19.4395C20.1222 2.16436 20.6763 2.71847 20.6763 3.40114V7.77579V7.77885C20.6763 8.37581 21.1599 8.8595 21.7569 8.8595C22.3539 8.8595 22.8376 8.37581 22.8376 7.77885V7.77579V2.47356Z" />
        <path d="M4.17261 6.69834C4.17261 7.2953 4.6563 7.77899 5.25326 7.77899H17.6211C18.218 7.77899 18.7017 7.2953 18.7017 6.69834C18.7017 6.10138 18.218 5.61769 17.6211 5.61769H5.25326C4.65936 5.61463 4.17261 6.10138 4.17261 6.69834Z" />
        <path d="M4.17261 13.2647C4.17261 13.8617 4.6563 14.3454 5.25326 14.3454H11.2872C11.8841 14.3454 12.3678 13.8617 12.3678 13.2647C12.3678 12.6678 11.8841 12.1841 11.2872 12.1841H5.25632C4.65936 12.1841 4.17261 12.6678 4.17261 13.2647Z" />
        <path d="M4.17261 19.6444C4.17261 20.2413 4.6563 20.725 5.25326 20.725H9.17483C9.77179 20.725 10.2555 20.2413 10.2555 19.6444C10.2555 19.0474 9.77179 18.5637 9.17483 18.5637H5.25632C4.65936 18.5637 4.17261 19.0474 4.17261 19.6444Z" />
    </svg>
);
export default Audit;
