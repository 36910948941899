import { createSlice } from '@reduxjs/toolkit';

import {
    getAnimalDetails,
    getApplicantDetails,
    getBankAccountDetails,
    getBankAssessmentDetails,
    getBankMaster,
    getCreditReport,
    getCropDetails,
    getDisbursalDetails,
    getHouseholdMembersDetails,
    getIncomeDetails,
    getIncomeExpenditure,
    getOtherKycDetails,
    getPanDetails,
    getResidenceDetails,
    getSourcingDetails,
    getSurveyDetails,
} from './thunks';

const initialState = {
    value: 0,
    customerID: '',
    prospectDetails: {},
    overViewDetails: {},
    creditReport: {},
    finalConfirmation: {},
    disbursalDetails: {},
    loanStatus: '',
};

export const prospectDetailsSlice = createSlice({
    name: 'prospectDetails',
    initialState,
    reducers: {
        setCustomerID(state, action) {
            state.customerID = action.payload;
        },
        setLoanStatus(state, action) {
            state.loanStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCreditReport.fulfilled, (state, action) => {
                state.creditReport = action.payload?.data;
            })
            .addCase(getDisbursalDetails.fulfilled, (state, action) => {
                state.disbursalDetails = action.payload?.data;
            })
            .addCase(getSourcingDetails.fulfilled, (state, action) => {
                state.sourcingDetails = action.payload?.data;
            })
            .addCase(getSurveyDetails.fulfilled, (state, action) => {
                state.surveyDetails = action.payload?.data;
            })
            .addCase(getResidenceDetails.fulfilled, (state, action) => {
                state.residenceDetails = action.payload?.data;
            })
            .addCase(getBankMaster.fulfilled, (state, action) => {
                state.bankMaster = action.payload?.data;
            })
            .addCase(getPanDetails.fulfilled, (state, action) => {
                state.panDetails = action.payload?.data;
            })
            .addCase(getAnimalDetails.fulfilled, (state, action) => {
                state.animalDetails = action.payload?.data;
            })
            .addCase(getOtherKycDetails.fulfilled, (state, action) => {
                state.otherKycDetails = action.payload?.data;
            })
            .addCase(getHouseholdMembersDetails.fulfilled, (state, action) => {
                state.houseHoldDetails = action.payload?.data;
            })
            .addCase(getBankAccountDetails.fulfilled, (state, action) => {
                state.bankDetails = action.payload?.data;
            })
            .addCase(getApplicantDetails.fulfilled, (state, action) => {
                state.applicantDetails = action.payload?.data;
            })
            .addCase(getIncomeExpenditure.fulfilled, (state, action) => {
                state.incomeExpenditure = action.payload?.data;
            })
            .addCase(getIncomeDetails.fulfilled, (state, action) => {
                state.incomeDetails = action.payload?.data;
            })
            .addCase(getBankAssessmentDetails.fulfilled, (state, action) => {
                state.bankAssessmentDetails = action.payload?.data;
            })
            .addCase(getCropDetails.fulfilled, (state, action) => {
                state.cropDetails = action.payload.data;
            })
            .addCase('prospectDetails/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const { setCustomerID, setLoanStatus } = prospectDetailsSlice.actions;

export default prospectDetailsSlice.reducer;
