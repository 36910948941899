/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';

import {
    getBCSeedsData,
    getDashboardProductsList,
    getDisbursmentAnalysis,
    getLoanDisbursementData,
    getOverallData,
    getStatesDrillDown,
} from './thunks';

const initialState = {
    dashboardSelectedProduct: '',
    dashboardSelectedProductName: '',
    responseTimeStamp: '',
    statesData: [],
    sampleBcData: [],
    dashboardProductsList: [],
    summaryData: {},
    loanDisbursementData: {},
    graphData: [],
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setBcSeedsData(state, action) {
            state.sampleBcData = action.payload?.data;
        },
        setDashboardProductCode(state, action) {
            state.dashboardSelectedProduct = action?.payload;
        },
        setDashboardProductName(state, action) {
            state.dashboardSelectedProductName = action?.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase('dashboard/CLEAR_STATE', () => {
                return initialState;
            })
            .addCase(getStatesDrillDown.fulfilled, (state, action) => {
                state.statesData = action.payload.data;
            })
            .addCase(getBCSeedsData.fulfilled, (state, action) => {
                state.sampleBcData = action.payload.data;
            })
            .addCase(getDashboardProductsList.fulfilled, (state, action) => {
                state.dashboardProductsList = action.payload.data;
                state.dashboardSelectedProduct = action.payload.data[0].productcode;
                state.dashboardSelectedProductName =
                    action.payload.data[0].productname;
            })
            .addCase(getOverallData.fulfilled, (state, action) => {
                state.summaryData = action.payload.data;
            })
            .addCase(getLoanDisbursementData.fulfilled, (state, action) => {
                state.loanDisbursementData = action.payload.data;
                state.responseTimeStamp = action?.payload?.timestamp;
            })
            .addCase(getDisbursmentAnalysis.fulfilled, (state, action) => {
                state.graphData = action.payload.data;
            });
    },
});

export const { actions } = dashboardSlice;
export const { setDashboardProductCode, setDashboardProductName } =
    dashboardSlice.actions;

export default dashboardSlice.reducer;
