import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InfoIcon from '@mui/icons-material/Info';
import {
    Box,
    Button,
    Link,
    List,
    ListItem,
    ListItemText,
    Popover,
    Tooltip,
    Typography,
} from '@mui/material';

import PropTypes from 'prop-types';

import NotificationSVG from '../assets/svg/NotificationSVG';
import NoDataCard from '../features/prospect-details/components/NoDataCard';
import { selectNotificationReportDownloadData } from '../redux/selector';
import { getLatestReportDownloadLink } from '../redux/thunks';
import { theme } from '../theme';
import { flattenArrayAndRemoveNull } from '../utils';
import convertTextFormat from '../utils/TextTransform';

const Notification = ({ userId }) => {
    const dispatch = useDispatch();
    const [hasDot, setHasDot] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const notificationReportDownloadData = useSelector(
        selectNotificationReportDownloadData
    );

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        setHasDot(notificationReportDownloadData?.length ? true : false);
    }, [notificationReportDownloadData]);

    const notificationApicall = () => {
        dispatch(
            getLatestReportDownloadLink({
                data: { id: [userId], type: [] },
            })
        );
    };

    function generateTitle(requestPayload) {
        const content = Object.keys(requestPayload).map((key) => {
            const value = requestPayload[key];
            if (Array.isArray(value) && value.length > 0) {
                return (
                    <div key={key}>
                        <Box sx={{ marginBottom: 1 }}>
                            <Typography variant="subtitle1" component="strong">
                                <strong>{key.toUpperCase()}</strong>:{' '}
                                {value.join(', ')}
                            </Typography>
                        </Box>
                    </div>
                );
            } else if (typeof value === 'object' && Object.keys(value).length > 0) {
                return Object.keys(value).map((subKey) => {
                    const subValue = value[subKey];
                    if (subValue != null && subValue !== '') {
                        return (
                            <div key={`${key}-${subKey}`}>
                                <Box sx={{ marginBottom: 1 }}>
                                    <Typography
                                        variant="subtitle1"
                                        component="strong"
                                    >
                                        <strong>{subKey.toUpperCase()}</strong>:{' '}
                                        {subValue}
                                    </Typography>
                                </Box>
                            </div>
                        );
                    } else {
                        return null;
                    }
                });
            } else return null;
        });
        const filteredContent = flattenArrayAndRemoveNull(content);
        return filteredContent.length > 0 ? filteredContent : null;
    }

    useEffect(() => {
        notificationApicall();
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClick = (event) => {
        notificationApicall();
        setAnchorEl(event.currentTarget);
    };
    return (
        <>
            <Button onClick={handleClick}>
                <NotificationSVG hasDot={hasDot} dotColor="red" />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div
                    style={{
                        maxWidth: '300px',
                        width: '250px',
                        maxHeight: '400px',
                        overflowY: 'auto',
                    }}
                    className="notification-container"
                >
                    <Typography
                        style={{
                            fontWeight: 'bold',
                            backgroundColor: theme.palette.secondary.main,
                            position: 'sticky',
                            top: 0,
                            zIndex: 100,
                            padding: '10px',
                            borderTop: '1px solid #ddd',
                        }}
                    >
                        Notifications
                    </Typography>
                    <List dense={true}>
                        {notificationReportDownloadData?.length ? (
                            notificationReportDownloadData?.map((item, index) => (
                                <ListItem key={`reportDownload${index}`}>
                                    <ListItemText
                                        primary={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.5rem',
                                                }}
                                            >
                                                {`${convertTextFormat(
                                                    item?.type,
                                                    'TITLE_CASE'
                                                )} Report`}
                                                <Tooltip
                                                    aria-label="Info"
                                                    title={
                                                        generateTitle(
                                                            item.requestPayload
                                                        ) ? (
                                                            <div>
                                                                {generateTitle(
                                                                    item.requestPayload
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                No filter selected
                                                            </div>
                                                        )
                                                    }
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                overflowY: 'auto',
                                                                padding: '0.5rem',
                                                                bgcolor:
                                                                    'white!important',
                                                                border: '1px solid rgba(0, 0, 0, 0.25)',
                                                                color: '#1B1B1B !important',
                                                                fontWeight: 400,
                                                                fontSize: '16px',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <InfoIcon
                                                        sx={{
                                                            fontSize: '1.3rem',
                                                            color: 'grey',
                                                            marginBottom: '3px',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        }
                                        secondary={item?.createdAt}
                                    />

                                    <Link
                                        target="_blank"
                                        href={item?.url}
                                        rel="noreferrer"
                                    >
                                        {' '}
                                        <Tooltip
                                            title="Download Report"
                                            placement="right"
                                        >
                                            <CloudDownloadIcon
                                                sx={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    fontSize: '26px',
                                                    mr: 2,
                                                    marginLeft: '-50%',
                                                }}
                                            />
                                        </Tooltip>
                                    </Link>
                                </ListItem>
                            ))
                        ) : (
                            <NoDataCard customStyle={{ height: '4rem' }} />
                        )}
                    </List>
                </div>
            </Popover>
        </>
    );
};

export default Notification;

Notification.propTypes = {
    userId: PropTypes.string,
};
