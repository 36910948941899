import { isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { activeApiCountChange, showSnackBar } from '../redux/commonSlice';
import { theme } from '../theme';
import {
    allowedSkippableLoaders,
    allowedSnackBarActionTypes,
    hideLoaders,
} from './actionTypes';

// Loader Middleware
export const loaderMiddleware = (store) => (next) => (action) => {
    const isLoaderToHide = hideLoaders.find((loader) =>
        action.type?.includes(loader.type)
    );
    if (isLoaderToHide) return next(action);
    if (isPending(action)) {
        let skippable = true;
        const matchingLoader = allowedSkippableLoaders.find(
            (loader) => loader.type === action.type
        );

        if (matchingLoader) {
            skippable = matchingLoader.allowLoaderSkip;
        }

        store.dispatch(activeApiCountChange({ type: 'increment', skippable }));
    } else if (isRejected(action) || isFulfilled(action)) {
        store.dispatch(activeApiCountChange({ type: 'decrement' }));
    }
    return next(action);
};

// SnackBar Middleware
export const snackBarMiddleware = (store) => (next) => (action) => {
    const allowedAction = allowedSnackBarActionTypes.find(
        (allowedAction) => allowedAction.type === action?.type
    );

    if (allowedAction && isFulfilled(action)) {
        const response = action?.payload;

        if (
            response?.code === 'success' &&
            (allowedAction.showSnackbarFor === 'both' ||
                allowedAction.showSnackbarFor === 'success')
        ) {
            store.dispatch(
                showSnackBar({
                    snackBarOpen: true,
                    allowClose: true,
                    vertical: 'top',
                    horizontal: 'right',
                    duration: 3000,
                    severity: 'success',
                    snackBarMessage: response?.message,
                    color: theme.palette.common.primaryGreen,
                })
            );
        } else if (
            response?.code === 'failure' &&
            (allowedAction.showSnackbarFor === 'both' ||
                allowedAction.showSnackbarFor === 'failure')
        ) {
            store.dispatch(
                showSnackBar({
                    snackBarOpen: true,
                    allowClose: true,
                    vertical: 'top',
                    horizontal: 'right',
                    duration: 3000,
                    severity: 'error',
                    snackBarMessage: response?.message,
                    color: theme.palette.common.negativeRed,
                })
            );
        }
    }

    return next(action);
};
