import * as React from 'react';
const Retrigger = (props) => (
    <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M26.2861 13.2019C24.4394 12.8797 22.7227 12.3529 21.2601 11.2002C20.4359 10.5557 19.8596 9.73763 19.841 8.64071C19.8348 8.25648 19.9153 7.87225 19.9649 7.44463C19.8472 7.56858 19.7418 7.68013 19.6426 7.78549C17.8206 9.71904 15.7631 11.3241 13.278 12.3281C12.1253 12.7929 10.9354 13.1028 9.70838 13.2639C8.67963 13.4002 7.64469 13.5118 6.61594 13.6419C5.43225 13.7969 4.42209 14.2121 3.96969 15.4577C3.85194 14.3298 3.90152 13.2205 4.08124 12.1236C4.29815 10.7974 4.71956 9.53312 5.39507 8.36183C6.48579 6.47786 8.04751 5.12065 10.0244 4.23444C11.8093 3.42879 13.6994 3.08174 15.6454 3.18709C18.775 3.36062 21.4399 4.59388 23.5593 6.92406C24.9042 8.40521 25.7718 10.1343 26.1436 12.105C26.1808 12.2971 26.2118 12.4892 26.2428 12.6813C26.2614 12.8425 26.2676 12.9974 26.2861 13.2019Z" />
        <path d="M26.3233 19.0399C26.5526 18.0917 26.8191 17.1621 26.9926 16.214C27.1847 15.1976 27.1724 14.1688 27.067 13.1339C26.8625 11.1694 26.2118 9.37214 25.1272 7.72986C23.6833 5.54841 21.7311 3.99289 19.2894 3.0571C17.5356 2.38159 15.7136 2.1461 13.8544 2.31962C11.3383 2.56132 9.09485 3.49711 7.15509 5.13319C5.39506 6.62054 4.11222 8.44874 3.44291 10.6488C2.0981 15.0798 2.94093 19.0833 5.9962 22.5786C7.75623 24.5927 10.012 25.7888 12.6211 26.3465C12.7822 26.3837 12.9496 26.3899 13.1107 26.4271C13.2904 26.4643 13.4268 26.4333 13.5879 26.3155C14.0155 25.9933 14.5671 26.0738 14.9265 26.4705C15.2674 26.8485 15.255 27.4435 14.9017 27.8215C14.5671 28.1871 14.0155 28.2057 13.5755 27.9454C13.3214 27.7967 13.0115 27.7285 12.7141 27.6789C9.95627 27.208 7.5889 25.9809 5.58718 24.0473C4.19898 22.7025 3.16403 21.116 2.44515 19.3188C2.40796 19.2196 2.34599 19.0895 2.26543 19.0585C1.31724 18.7053 0.759484 17.974 0.443422 17.0754C-0.23828 15.1604 -0.182504 13.2888 0.951601 11.5412C1.1809 11.1818 1.58992 10.9401 1.91218 10.6426C2.01754 10.5434 2.14768 10.4381 2.19106 10.3079C3.41812 6.66392 5.7607 3.99289 9.2002 2.30103C10.7991 1.51397 12.4972 1.10495 14.282 1.01819C17.3187 0.869454 20.1012 1.63172 22.6049 3.34837C25.121 5.07122 26.8439 7.4014 27.7983 10.3017C27.8664 10.5062 27.9532 10.6488 28.1577 10.7603C28.7341 11.064 29.1307 11.566 29.391 12.1485C30.1966 13.9519 30.24 15.7616 29.298 17.534C28.6721 18.6867 27.4388 19.2878 26.3233 19.0399Z" />
        <path d="M8.44412 16.1273C8.45032 14.8445 9.42329 13.8901 10.7061 13.8963C11.865 13.9025 12.8442 14.9374 12.838 16.1459C12.838 17.4039 11.8464 18.4141 10.6256 18.4141C9.4047 18.4141 8.43792 17.4039 8.44412 16.1273Z" />
        <path d="M19.2088 13.8962C20.4235 13.8962 21.4027 14.894 21.4089 16.1335C21.4151 17.3791 20.4297 18.4141 19.246 18.4141C18.0127 18.4141 17.0212 17.4163 17.015 16.1644C17.0088 14.9064 17.9818 13.8962 19.2088 13.8962Z" />
        <path d="M12.0076 21.804C14.0713 22.7274 16.104 22.7088 18.1553 21.7544C18.3908 22.2936 18.2607 22.7955 17.9508 23.2541C17.4674 23.9544 16.7609 24.3201 15.9429 24.4626C14.5671 24.7043 13.3462 24.3944 12.367 23.3471C11.8898 22.8265 11.7845 22.2998 12.0076 21.804Z" />
    </svg>
);
export default Retrigger;
