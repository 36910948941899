import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';

export const getCustomerSupportApiList = createAsyncThunk(
    'CUSTOMER_SUPPORT',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiService.post(
                '/data/v1/report/customers/technicalsupport',
                {
                    payload: {
                        data: payload.filterRequestPayloadData,
                        skip: payload.skip,
                        limit: payload.limit,
                    },
                },
                { signal: payload.signal, cache: false }
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const retry = createAsyncThunk(
    'RETRY',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiService.post(
                '/bank/api/v1/dlq/retry',
                payload,
                { cache: false }
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
