import * as React from 'react';
const Dashboard = (props) => (
    <svg
        width={30}
        height={30}
        viewBox="0 0 34 34"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2 2.97402C2 2.4361 2.4361 2 2.97402 2H12.3247C12.8626 2 13.2987 2.4361 13.2987 2.97402V17C13.2987 17.5379 12.8626 17.974 12.3247 17.974H2.97402C2.4361 17.974 2 17.5379 2 17V2.97402ZM20.7013 2.97402C20.7013 2.4361 21.1375 2 21.6753 2H31.026C31.564 2 32 2.43608 32 2.97402V5.31169C32 5.84964 31.564 6.28571 31.026 6.28571H21.6753C21.1375 6.28571 20.7013 5.84962 20.7013 5.31169V2.97402ZM2 26.3507C2 25.8127 2.43608 25.3765 2.97402 25.3765H12.3247C12.8626 25.3765 13.2987 25.8127 13.2987 26.3507V31.026C13.2987 31.564 12.8626 32 12.3247 32H2.97402C2.43608 32 2 31.564 2 31.026V26.3507ZM20.7013 14.6623C20.7013 14.1244 21.1375 13.6883 21.6753 13.6883H31.026C31.564 13.6883 32 14.1244 32 14.6623V31.026C32 31.564 31.564 32 31.026 32H21.6753C21.1375 32 20.7013 31.564 20.7013 31.026V14.6623Z"
            strokeWidth={3}
        />
    </svg>
);
export default Dashboard;
