import { createSlice } from '@reduxjs/toolkit';

import { getCustomerSupportApiList } from './thunks';
const initialState = {
    customerSupport: {
        data: {},
    },
    apiRequest: {},
    membersList: {},
    drawerOpen: false,
};

export const customerSupportSlice = createSlice({
    name: 'customerSupport',
    initialState,
    reducers: {
        openDrawer: (state) => {
            state.drawerOpen = true;
        },
        closeDrawer: (state) => {
            state.drawerOpen = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomerSupportApiList.fulfilled, (state, action) => {
                state.customerSupport.data = action.payload;
            })
            .addCase('customerSupport/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const { openDrawer, closeDrawer } = customerSupportSlice.actions;

export default customerSupportSlice.reducer;
