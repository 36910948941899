// thunks.js
import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';
export const loginUser = createAsyncThunk(
    'DATA_FETCH/LOGIN',
    async (values, { rejectWithValue }) => {
        const credData = { payload: { ...values } };
        const header = {
            'device-type': 'WEB',
        };
        try {
            const response = await apiService.post('/auth/v2/auth/login', credData, {
                headers: header,
                cache: false,
            });

            window.sessionStorage.setItem('partnerId', response?.data?.partnerId);
            window.sessionStorage.setItem('aT', response?.data?.accessToken);
            window.sessionStorage.setItem('rT', response?.data?.refreshToken);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const setPassword = createAsyncThunk(
    'DATA_FETCH/SET_PASSWORD',
    async (values, { rejectWithValue }) => {
        const newPasswordData = { payload: { ...values } };
        try {
            const response = await apiService.put(
                '/auth/v2/auth/password/update',
                newPasswordData
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const logoutApi = createAsyncThunk(
    'LOGOUT',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService.get('/auth/v2/auth/logout', {
                cache: false,
            });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
