import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../../../service/apiService';
import { disableButton, enableButton } from '../../../redux/slice';

export const disbursalReport = createAsyncThunk(
    'DISBURSAL_REPORT',
    async (payload, { dispatch, rejectWithValue }) => {
        const reportName =
            payload?.userData?.role === 'FBA' ? `bank-disbursement` : 'disbursement';
        try {
            const response = await apiService.post(
                `/data/v1/report/${reportName}/reports`,
                {
                    payload: {
                        data: payload.filterRequestPayloadData,
                        skip: payload.skip,
                        limit: payload.limit,
                    },
                },
                { signal: payload.signal }
            );

            if (
                response?.code === 'failure' ||
                response?.data?.filteredData?.count === 0
            ) {
                dispatch(disableButton('disbursement'));
            } else {
                dispatch(enableButton('disbursement'));
            }
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
