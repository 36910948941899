import { createSlice } from '@reduxjs/toolkit';

import {
    getCustomerLifecycleMasterLevel,
    getLatestReportDownloadLink,
} from './thunks';
const initialState = {
    path: '',
    reportPath: '',
    masterData: {
        customerLifecycle: {},
    },
    activeCustomer: {},
    notificationReportDownloadData: [],
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        clearGlobaldata: (state) => {
            state.path = '';
            state.reportPath = '';
        },
        navigatePath: (state, action) => {
            state.path = action.payload;
        },
        setSelectedCustomer: (state, action) => {
            state.activeCustomer = action.payload;
        },
        reportsPath(state, action) {
            state.reportPath = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomerLifecycleMasterLevel.fulfilled, (state, action) => {
                state.masterData.customerLifecycle = action?.payload?.data;
            })
            .addCase(getLatestReportDownloadLink.fulfilled, (state, action) => {
                state.notificationReportDownloadData = action?.payload?.data;
            })
            .addCase('global/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const { navigatePath, clearGlobaldata, setSelectedCustomer, reportsPath } =
    globalSlice.actions;

export default globalSlice.reducer;
