import { Card } from '@mui/material';

import PropTypes from 'prop-types';

const NoDataCard = ({ customStyle }) => {
    const defaultStyles = {
        p: '1.25rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const combinedStyles = {
        ...defaultStyles,
        ...customStyle,
    };

    return (
        <Card elevation={3} style={combinedStyles}>
            No data available
        </Card>
    );
};

NoDataCard.propTypes = {
    customStyle: PropTypes.object,
};
export default NoDataCard;
