import { createSlice } from '@reduxjs/toolkit';

import { getCreditReportList } from './thunks';

const initialState = {
    creditReport: {
        data: {},
    },
};

export const creditReportSlice = createSlice({
    name: 'creditReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCreditReportList.fulfilled, (state, action) => {
                state.creditReport.data = action.payload;
            })
            .addCase('creditReport/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

export default creditReportSlice.reducer;
