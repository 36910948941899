import * as React from 'react';
const ProcessLoan = (props) => (
    <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M14.921 5.07266C14.921 3.39575 14.921 1.7398 14.921 0C15.4705 0.0419229 16.0411 0.0419177 16.5695 0.125763C19.4861 0.544992 22.128 1.59307 24.4317 3.41671C26.2281 4.84209 27.5596 6.64477 28.5741 8.67803C29.1236 9.76803 29.5463 10.879 29.7576 12.0947C30.0535 13.7297 30.0746 15.3647 29.8422 17.0207C29.504 19.4103 28.6375 21.6112 27.2637 23.6026C26.0379 25.4053 24.4105 26.7887 22.5084 27.8997C21.3248 28.5914 20.0779 29.1364 18.7675 29.4927C17.0767 29.9539 15.3437 30.1006 13.5895 29.9329C11.5394 29.7233 9.61615 29.1154 7.79856 28.1931C7.54494 28.0674 7.31246 27.8997 7.05884 27.7529C7.92536 26.3485 8.77076 24.986 9.63729 23.5816C9.7641 23.6655 9.8909 23.7283 10.0177 23.8122C10.9054 24.2733 11.8564 24.5458 12.8286 24.7135C14.3715 24.986 15.9143 24.9022 17.4149 24.5878C19 24.2524 20.3949 23.5187 21.5785 22.4078C23.0368 21.0662 24.0935 19.4941 24.6007 17.5866C24.9812 16.1613 24.9812 14.7149 24.7698 13.2476C24.5373 11.7384 24.009 10.3759 23.1002 9.13918C22.1491 7.86053 20.9444 6.85438 19.5072 6.12073C18.1123 5.40805 16.6118 5.07266 15.0267 5.09363C15.0267 5.07266 14.9844 5.07266 14.921 5.07266Z" />
        <path d="M12.6034 20.294C12.4579 20.1847 12.3332 20.141 12.2709 20.0535C11.1902 18.9384 10.1303 17.8233 9.04962 16.6863C8.73789 16.3583 8.75867 16.1834 9.04962 15.8554C9.31979 15.5712 9.58996 15.265 9.88091 14.9808C10.1926 14.6965 10.3381 14.6965 10.6291 14.9808C11.1071 15.4618 11.585 15.9647 12.063 16.4676C12.3955 16.8175 12.541 16.8175 12.8735 16.4676C14.9933 14.303 17.1131 12.1165 19.2329 9.95179C19.5446 9.62382 19.6693 9.62382 20.0018 9.95179C20.3551 10.3235 20.6876 10.6952 21.0409 11.0669C21.2072 11.2637 21.228 11.4605 21.0617 11.6354C20.8331 11.8759 20.6253 12.1165 20.3967 12.357C17.9859 14.8496 15.5752 17.3422 13.1645 19.813C13.019 20.0098 12.8112 20.1191 12.6034 20.294Z" />
        <path d="M5.55076 16.7646C5.71438 17.3249 5.85754 17.8618 6.04161 18.3986C6.24614 18.9589 6.26659 18.9589 5.75529 19.2156C4.44635 19.8692 3.13741 20.4995 1.78756 21.1764C1.66485 20.873 1.52169 20.5928 1.43988 20.2894C1.25581 19.6825 1.07174 19.0522 0.908119 18.422C0.867215 18.2586 0.846763 18.1185 1.07174 18.0485C2.54429 17.605 4.0373 17.1848 5.55076 16.7646Z" />
        <path d="M11.1207 5.29412C10.3469 3.79365 9.59731 2.29319 8.82355 0.79272C8.87191 0.772444 8.89609 0.731893 8.92027 0.731893C9.86329 0.488575 10.8063 0.265533 11.7493 0.0222139C11.9911 -0.0386158 12.1362 0.0222151 12.1846 0.245257C12.5231 1.68489 12.8858 3.1448 13.2243 4.58444C13.2485 4.72637 13.2485 4.82775 13.0551 4.82775C12.4022 4.84803 11.7977 5.0508 11.2174 5.29412C11.1932 5.29412 11.1448 5.29412 11.1207 5.29412Z" />
        <path d="M7.05879 9.66152C6.77805 10.2404 6.4973 10.7764 6.21655 11.3339C6.14637 11.4625 6.09957 11.5054 5.9358 11.4411C4.41508 10.905 2.87096 10.369 1.32685 9.83304C1.18647 9.79015 1.04609 9.74728 0.882324 9.7044C0.952511 9.55432 0.97591 9.42567 1.0461 9.29703C1.49062 8.56806 1.91174 7.8391 2.37965 7.11013C2.40305 7.06725 2.56682 7.04581 2.637 7.06725C3.03473 7.26021 3.40907 7.49605 3.7834 7.71046C4.76602 8.28934 5.77203 8.86823 6.75465 9.44711C6.87163 9.53288 6.98861 9.61864 7.05879 9.66152Z" />
        <path d="M6.6367 21.1765C7.06439 21.5804 7.49208 21.9659 7.94115 22.3881C7.83423 22.5166 7.70592 22.6451 7.57761 22.7552C6.65809 23.6731 5.71718 24.5726 4.79765 25.4905C4.69073 25.5822 4.62657 25.6373 4.49827 25.5272C3.89951 24.9581 3.30074 24.4074 2.70198 23.8383C2.59506 23.7465 2.65921 23.6914 2.76614 23.618C3.85674 22.9388 4.92596 22.2596 6.01656 21.5804C6.20902 21.4519 6.42286 21.305 6.6367 21.1765Z" />
        <path d="M4.41174 4.54278C5.10974 3.78446 5.89781 3.21571 6.73091 2.64697C6.82098 2.7523 6.91104 2.85761 7.0011 2.984C7.87923 4.16361 8.75737 5.3643 9.6355 6.54391C9.74808 6.71243 9.72556 6.79668 9.56795 6.902C9.14014 7.21797 8.71233 7.53394 8.32956 7.87097C8.17194 7.99736 8.08188 7.93416 7.99181 7.82884C7.22626 7.11265 6.4382 6.39646 5.67265 5.6592C5.24484 5.3011 4.83955 4.94301 4.41174 4.54278Z" />
        <path d="M5.29412 13.0955C5.27206 13.324 5.22794 13.5525 5.22794 13.7619C5.20588 14.0475 5.20588 14.3521 5.20588 14.6377C5.20588 14.771 5.16177 14.8281 4.9853 14.8281C3.39706 14.8852 1.83088 14.9423 0.242645 14.9995C0.110292 14.9995 0 15.0185 0 14.8471C0.0220588 14.0856 0.0661752 13.3431 0.088234 12.5815C0.088234 12.5053 0.132352 12.4482 0.154411 12.353C1.875 12.6196 3.55147 12.848 5.29412 13.0955Z" />
    </svg>
);
export default ProcessLoan;
