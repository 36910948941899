const clearAllReducersMiddleware = (store) => (next) => (action) => {
    if (action.type === 'DESTROY_SESSION') {
        const currentState = store.getState();

        const reducerKeys = Object.keys(currentState);

        reducerKeys.forEach((key) => {
            store.dispatch({ type: `${key}/CLEAR_STATE` });
        });
    }

    return next(action);
};

export default clearAllReducersMiddleware;
