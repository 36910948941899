/**
 * Asynchronously loads the component for HomePage
 */

import loadable from '../../utils/loadable';

const AuditPage = loadable(() => import('../Audit'), {
    fallback: <div className="flex-center">Audit loading..</div>,
});

export default AuditPage;
