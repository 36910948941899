import { createSlice } from '@reduxjs/toolkit';

import { getProspectReportList } from './thunk';

const initialState = {
    prospectReport: {
        data: {},
    },
};

export const prospectReportSlice = createSlice({
    name: 'prospectReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProspectReportList.fulfilled, (state, action) => {
                state.prospectReport.data = action.payload;
            })
            .addCase('prospectReport/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

export default prospectReportSlice.reducer;
