//constant name must with route path
export const loanApproval = {
    pincode: [],
    stakeholderCode: [],
    productCode: [],
    // villageName: [],
    date: {
        startDate: '',
        endDate: '',
    },
};
export const customerDashboard = {
    stateCode: [],
    districtCode: [],
    productCode: [],
    bc: [],
    stage: [],
    status: [],
    date: {
        startDate: '',
        endDate: '',
    },
    // searchString: '',
};

export const auditFilter = {
    pincode: [],
    stakeholderName: [],
    productName: [],
    villageName: [],
    // createdDate: {
    //     fromDate: '',
    //     toDate: '',
    // },
};

export const reports = {
    pincode: [],
    stakeholderCode: [],
    productCode: [],
    stage: [],
    date: {
        startDate: '',
        endDate: '',
    },
    // searchString: '',
};

export const retrigger = {
    productCode: [],
    apiName: [],
};
