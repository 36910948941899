import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';

export const getCreditReport = createAsyncThunk(
    'PROSPECT_CREDIT_REPORT',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/credit`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getDisbursalDetails = createAsyncThunk(
    'PROSPECT_DISBURSAL_DETAILS',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/disbursaldetails`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getSourcingDetails = createAsyncThunk(
    'PROSPECT_SOURCING_DETAILS',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/sourcingdetails`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getSurveyDetails = createAsyncThunk(
    'PROSPECT_SURVEY_DETAILS',
    async (custId, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const productId = state?.global?.activeCustomer?.productCode;
            if (!productId) return { data: [] };
            const headers = {
                'product-id': productId,
            };
            const response = await apiService.get(
                `/haystack/v1/haystack/${custId}/survey`,
                {
                    headers,
                }
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getResidenceDetails = createAsyncThunk(
    'PROSPECT_RESIDENCE_DETAILS',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/residencedetails`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBankMaster = createAsyncThunk(
    'PROSPECT_BANKMASTER_DETAILS',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/bankmaster`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getPanDetails = createAsyncThunk(
    'PROSPECT_PAN_DETAILS',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/pandetails`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getOtherKycDetails = createAsyncThunk(
    'PROSPECT_OTHERKYC_DETAILS',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/otherkycdetails`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getHouseholdMembersDetails = createAsyncThunk(
    'PROSPECT_HOUSEHOLD_DETAILS',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/members`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBankAccountDetails = createAsyncThunk(
    'PROSPECT_BANK_DETAILS',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/account`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getApplicantDetails = createAsyncThunk(
    'PROSPECT_APPLICANT_DETAILS',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/applicantdetails`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getIncomeExpenditure = createAsyncThunk(
    'INCOME_EXPENDITURE_DETAILS',
    async (custId, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const productId = state?.global?.activeCustomer?.productCode;
            if (!productId) return { data: [] };
            const headers = {
                'product-id': productId,
            };
            const response = await apiService.get(
                `/haystack/v1/haystack/${custId}/incomeExpenditure`,
                { headers }
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getIncomeDetails = createAsyncThunk(
    'PROSPECT_INCOME_DETAILS',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/data/v1/report/${custId}/incomes`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAnimalDetails = createAsyncThunk(
    'animal_Details',
    async (custId, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const productId = state?.global?.activeCustomer?.productCode;
            if (!productId) return { data: [] };

            const headers = {
                'product-id': productId,
            };
            const response = await apiService.get(
                `/haystack/v1/allied/${custId}/info`,
                { headers }
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBankAssessmentDetails = createAsyncThunk(
    'BANK_ASSESSMENT_DETAILS',
    async (custId, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const productId = state?.global?.activeCustomer?.productCode;
            if (!productId) return { data: {} };

            const headers = {
                'product-id': productId,
            };
            const response = await apiService.get(
                `/main/customer/${custId}/bankAssessmentQuestionnaire`,
                {
                    headers,
                }
            );

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getCropDetails = createAsyncThunk(
    'CROP_DETAILS',
    async (custId, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const productId = state?.global?.activeCustomer?.productCode;
            if (!productId) return { data: [] };
            const headers = {
                'product-id': productId,
            };
            const response = await apiService.get(
                `/haystack/v1/kcc/${custId}/crop`,
                {
                    headers,
                }
            );

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getCreditReportHTML = createAsyncThunk(
    'PROSPECT_CREDIT_HTML',
    async (custId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(
                `/credit/v1/customer/${custId}/creditReport`
            );

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
