import { createSelector } from '@reduxjs/toolkit';

const login = (state) => state?.login;
export const userInfo = createSelector([login], (state) => state?.userInfo);
export const selectLoginData = createSelector([login], (state) => state?.data);

export const selectLoading = createSelector([login], (state) => state?.loading);
export const selectupdatePasscodeResponse = createSelector(
    [login],
    (state) => state?.setPass
);
export const selectUserDetails = createSelector([login], (state) => state);
export const selectUserInfo = createSelector([login], (state) => state.userInfo);

export const selectUserId = createSelector([login], (state) => state.userId);
