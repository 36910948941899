import { createSlice } from '@reduxjs/toolkit';

import { disbursalReport } from './thunks';

const initialState = {
    disbursalReport: {
        data: {},
    },
};

export const disbursalReportSlice = createSlice({
    name: 'disbursalReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(disbursalReport.fulfilled, (state, action) => {
                state.disbursalReport.data = action.payload;
            })
            .addCase('disbursalReport/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

export default disbursalReportSlice.reducer;
