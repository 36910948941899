import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabIndicatorPosition: {},
    buttonState: {
        disbursal: false,

        prospect: false,
        credit: false,
    },

    reportName: 'customer',
};

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setValueIndex(state, action) {
            state.tabIndicatorPosition = action.payload;
        },
        clearValueIndex(state) {
            state.tabIndicatorPosition = {};
        },
        enableButton: (state, action) => {
            switch (action.payload) {
                case 'disbursement':
                    state.buttonState.disbursal = false;
                    break;
                case 'customer':
                    state.buttonState.prospect = false;
                    break;
                case 'credit':
                    state.buttonState.credit = false;
                    break;
                default:
                    break;
            }
        },

        disableButton: (state, action) => {
            switch (action.payload) {
                case 'disbursement':
                    state.buttonState.disbursal = true;
                    break;

                case 'customer':
                    state.buttonState.prospect = true;
                    break;
                case 'credit':
                    state.buttonState.credit = true;
                    break;
                default:
                    break;
            }
        },

        changeReportName: (state, action) => {
            state.reportName = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase('reports/CLEAR_STATE', () => {
            return initialState;
        });
    },
});

// Action creators are generated for each case reducer function

export const {
    setValueIndex,

    clearValueIndex,

    enableButton,
    disableButton,

    changeReportName,
} = reportsSlice.actions;

export default reportsSlice.reducer;
