export const allowedSnackBarActionTypes = [
    { type: 'LOGOUT/fulfilled', showSnackbarFor: 'both' },
    { type: 'RETRY/fulfilled', showSnackbarFor: 'both' },
    { type: 'APPROVE_REJECT_LOAN/fulfilled', showSnackbarFor: 'both' },
    { type: 'DATA_FETCH/LOGIN/fulfilled', showSnackbarFor: 'failure' },
    { type: 'DATA_FETCH/SET_PASSWORD/fulfilled', showSnackbarFor: 'both' },
    { type: 'PROSPECT_ALL_DETAILS/fulfilled', showSnackbarFor: 'failure' },
    { type: 'PROSPECT_FINAL_CONFIRMATION/fulfilled', showSnackbarFor: 'failure' },
    { type: 'PROSPECT_DISBURSAL_DETAILS/fulfilled', showSnackbarFor: 'failure' },
    { type: 'GENERATE_REPORTS/fulfilled', showSnackbarFor: 'both' },
];

export const allowedSkippableLoaders = [
    { type: 'APPROVE_REJECT_LOAN/pending', allowLoaderSkip: false },
];
export const hideLoaders = [{ type: 'GET_LATEST_REPORT_DOWNLOAD_LIST' }];
