import forge from 'node-forge';

const generateKeyAndIV = () => {
    const _key = process.env.REACT_APP_KEY;
    const _iv = process.env.REACT_APP_IV;
    const key = forge.md.sha512.create();
    key.update(_key);
    const secretKey = key.digest().toHex().substring(0, 32);

    const encryptionIV = forge.md.sha512.create();
    encryptionIV.update(_iv);
    const iv = encryptionIV.digest().toHex().substring(0, 16);

    return { secretKey, iv };
};

const encrypt = (data) => {
    try {
        const { secretKey, iv } = generateKeyAndIV();
        const cipher = forge.cipher.createCipher('AES-CBC', secretKey);
        cipher.start({ iv });
        cipher.update(forge.util.createBuffer(JSON.stringify(data), 'utf8'));
        cipher.finish();
        const encryptedData = cipher.output.getBytes();
        const hexEcrypt = forge.util.bytesToHex(encryptedData);
        const encodedBase64 = btoa(hexEcrypt);
        return encodedBase64;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

const decrypt = (data) => {
    try {
        const { secretKey, iv } = generateKeyAndIV();
        const decipher = forge.cipher.createDecipher('AES-CBC', secretKey);
        decipher.start({ iv });
        decipher.update(forge.util.createBuffer(forge.util.hexToBytes(atob(data))));
        decipher.finish();
        const decryptedData = decipher.output.toString('utf8');
        return decryptedData;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

export { decrypt, encrypt };
