import PropTypes from 'prop-types';
const NotificationSVG = ({ hasDot = false, dotColor = 'red' }) => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="27"
                viewBox="0 0 24 27"
                fill="none"
            >
                <path
                    d="M23.5557 21.888C20.7101 20.6029 20.6019 15.0869 20.5998 14.9477V11.2471C20.5998 7.68545 18.4234 4.62281 15.3306 3.31877C15.324 1.48763 13.8326 0 12.0001 0C10.1675 0 8.6762 1.48772 8.66951 3.31868C5.5769 4.62272 3.40045 7.68536 3.40045 11.247V14.9476C3.39844 15.0868 3.2901 20.6028 0.444398 21.8879C0.120226 22.0344 -0.0579401 22.3873 0.0170102 22.735C0.091873 23.0828 0.399419 23.3312 0.755181 23.3312H7.96931C8.1172 24.1448 8.50188 24.9016 9.09024 25.5147C9.87169 26.3288 10.9051 26.7772 12 26.7772C13.0949 26.7772 14.1283 26.3288 14.9097 25.5147C15.4981 24.9016 15.8828 24.1447 16.0306 23.3312H23.2447C23.6005 23.3312 23.908 23.0829 23.983 22.735C24.058 22.3873 23.8799 22.0344 23.5557 21.888ZM19.8072 19.2455C20.1064 20.1524 20.5299 21.0632 21.1306 21.8211H2.8695C3.47006 21.0633 3.89364 20.1526 4.19279 19.2455H19.8072ZM12.0001 1.51016C12.829 1.51016 13.5299 2.06715 13.7492 2.82637C13.1842 2.70919 12.5992 2.64741 12.0001 2.64741C11.401 2.64741 10.816 2.70915 10.251 2.82637C10.4704 2.06719 11.1712 1.51016 12.0001 1.51016ZM4.91061 14.9523V11.247C4.91061 7.33787 8.09091 4.15758 12.0001 4.15758C15.9093 4.15758 19.0896 7.33787 19.0896 11.247V14.9584C19.0906 15.072 19.1082 16.2657 19.4091 17.7353H4.5909C4.89193 16.2643 4.90969 15.0685 4.91061 14.9523ZM12.0001 25.2672C10.8476 25.2672 9.84171 24.4362 9.51885 23.3312H14.4812C14.1584 24.4362 13.1527 25.2672 12.0001 25.2672Z"
                    fill="white"
                />
                <path
                    d="M12.0002 5.21692C8.75144 5.21692 6.1084 7.85987 6.1084 11.1085C6.1084 11.5256 6.44648 11.8637 6.8635 11.8637C7.28052 11.8637 7.61861 11.5256 7.61861 11.1085C7.61861 8.69259 9.58416 6.72708 12.0002 6.72708C12.4172 6.72708 12.7553 6.389 12.7553 5.97198C12.7553 5.55492 12.4172 5.21692 12.0002 5.21692Z"
                    fill="white"
                />

                {hasDot && <circle cx="18" cy="7" r="5" fill={dotColor} />}
            </svg>
        </div>
    );
};
NotificationSVG.propTypes = {
    hasDot: PropTypes.bool,
    dotColor: PropTypes.string,
};

export default NotificationSVG;
