import { createSlice } from '@reduxjs/toolkit';

import { modifyData } from '../../../utils/modifyRejectReaons';
import { getIndividualRejectReasons, getLoanApprovalListWithFilter } from './thunks';

const initialState = {
    filterListData: {},
    selectedCheckboxes: [],
    selectedGroupId: '',
    dialog: {
        open: false,
        content: null,
        type: null,
    },
    apiRequest: {},
    groupList: [],
    groupMembersList: {},
    isRejectDrawerOpen: false,
    otherReasons: false,
    rejectReasons: [],
    rejectedValuesCount: 0,
    selectedReasonValues: [], // New array for selected values
    rejectComments: '',
    groupId: '',
};

export const loanApprovalSlice = createSlice({
    name: 'loanApproval',
    initialState,
    reducers: {
        toggleAllCheckbox: (state, action) => {
            const { checkboxCount, filteredData } = action.payload;
            if (checkboxCount !== undefined) {
                const allSelected =
                    state.selectedCheckboxes.length === checkboxCount;

                if (allSelected) {
                    state.selectedCheckboxes = [];
                } else {
                    // Populate selectedCheckboxes with groupId of items with canReject as true
                    state.selectedCheckboxes = filteredData.rows.map(
                        (item) => item?.customerID
                    );
                }
            }
        },
        clearAllSelectedCheckboxes: (state) => {
            state.selectedCheckboxes = [];
        },
        toggleIndividualCheckbox: (state, action) => {
            const { itemId, isChecked } = action.payload;
            if (isChecked) {
                state.selectedCheckboxes?.push(itemId);
            } else {
                const indexToRemove = state.selectedCheckboxes?.indexOf(itemId);
                if (indexToRemove !== -1) {
                    state.selectedCheckboxes?.splice(indexToRemove, 1);
                }
            }
        },

        setSelectedGroupId: (state, action) => {
            state.selectedGroupId = action.payload;
        },
        openDialog: (state, action) => {
            state.dialog.open = true;
            state.dialog.content = action.payload.content;
            state.dialog.type = action.payload.type;
        },
        closeDialog: (state) => {
            state.dialog.open = false;
        },
        setApiRequest: (state, action) => {
            state.apiRequest = action.payload;
        },
        updateGroupList(state, action) {
            state.groupList = action.payload?.rows;
        },
        openCloseRejectDrawer(state, action) {
            state.isRejectDrawerOpen = action.payload;
            state.rejectComments = '';
            if (state.rejectReasons) {
                state.rejectReasons = state.rejectReasons.map((reason) => ({
                    ...reason,
                    type: false,
                }));
                state.selectedReasonValues = [];
                state.rejectedValuesCount = 0;
                state.otherReasons = false;
            }
        },
        updateRejectComments: (state, action) => {
            state.rejectComments = action.payload;
        },
        rejectSelectCheck(state, action) {
            state.rejectReasons[action.payload].type =
                !state.rejectReasons[action.payload].type;
            if (state.rejectReasons[action.payload].type) {
                state.selectedReasonValues.push(
                    state.rejectReasons[action.payload].id
                );
            } else {
                state.selectedReasonValues = state.selectedReasonValues.filter(
                    (value) => value !== state.rejectReasons[action.payload].id
                );
            }
            state.rejectedValuesCount = state.selectedReasonValues.length;
            if (
                state.rejectReasons[action.payload].value ===
                'Other reasons + comments'
            ) {
                state.otherReasons = state.rejectReasons[action.payload].type;
            }
        },
        clearGroupListData(state) {
            state.groupMembersList = {};
        },
        updateLoanAmount(state, action) {
            state.filterListData?.data?.filteredData?.rows.forEach(function (obj) {
                if (obj.customerID === action.payload?.customerID?.itemId) {
                    obj.loanAmount = action.payload.loanAmount;
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(getLoanApprovalListWithFilter.fulfilled, (state, action) => {
                const modifiedRows = action.payload?.data?.filteredData?.rows?.map(
                    (item) => ({
                        ...item,
                        initialLoanAmount: item?.loanAmount,
                    })
                );
                state.filterListData = {
                    ...action.payload,
                    data: {
                        ...action.payload?.data,
                        filteredData: {
                            ...action.payload?.data?.filteredData,
                            rows: modifiedRows,
                        },
                    },
                };
            })

            .addCase(getIndividualRejectReasons.fulfilled, (state, action) => {
                const modifiedData = modifyData(action.payload?.data);
                state.rejectReasons = modifiedData;
            })
            .addCase('loanApproval/CLEAR_STATE', () => {
                return initialState;
            });
    },
});

// Action creators are generated for each case reducer function
export const {
    toggleAllCheckbox,
    setApiRequest,
    setSelectedGroupId,
    clearAllSelectedCheckboxes,
    closeDialog,
    toggleIndividualCheckbox,
    updateGroupList,
    setGroupId,
    openCloseRejectDrawer,
    openDialog,
    rejectSelectCheck,
    updateRejectComments,
    clearGroupListData,
    updateLoanAmount,
} = loanApprovalSlice.actions;

export default loanApprovalSlice.reducer;
