import { createAsyncThunk } from '@reduxjs/toolkit';

import { routeConstant } from '../../../routes/routeConstant';
import apiService from '../../../service/apiService';
import { setLoanStatus } from '../../prospect-details/redux/slice';
import { closeDialog } from './slice';
export const getLoanApprovalListWithFilter = createAsyncThunk(
    'PROCESS_LOAN',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await apiService.post(
                '/data/v1/report/process/loans/haystack',
                {
                    payload: {
                        data: payload.filterRequestPayloadData,
                        skip: payload.skip,
                        limit: payload.limit,
                    },
                },
                { cache: false },
                { signal: payload.signal }
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const processLoan = createAsyncThunk(
    'APPROVE_REJECT_LOAN',
    async ({ value, mainRoute }, { dispatch, getState, rejectWithValue }) => {
        const {
            filter: { filterRequestPayloadData, skip, limit },
        } = getState();
        const apiData = { payload: { ...value } };

        try {
            const response = await apiService.post(
                `/main/customer/processLoanHaystack`,
                apiData,
                { cache: false }
            );
            if (response?.code === 'success' || response?.code === 'failure') {
                dispatch(closeDialog());
                if (
                    mainRoute ===
                    `/${routeConstant.LOAN_APPROVAL}/${routeConstant.PROSPECT_DETAILS}`
                )
                    dispatch(setLoanStatus('APPROVED/REJECTED'));
            }
            if (mainRoute === `/${routeConstant.LOAN_APPROVAL}`) {
                dispatch(
                    getLoanApprovalListWithFilter({
                        filterRequestPayloadData,
                        skip,
                        limit,
                    })
                );
            }
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getIndividualRejectReasons = createAsyncThunk(
    'INDIVIDUAL_REJECT_REASONS',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService.post('/data/v1/master/data', {
                payload: {
                    query: {
                        where: {
                            masterId: 'MAS10079',
                        },
                    },
                },
            });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
